.report-links-cta{
  background-color: $gray-light;
  margin-bottom: 115px;
  padding: 64px 8.5%;

  @media (max-width: 800px) {
    margin-bottom: 64px;
    padding: 32px 24px;
  }

  header.report-links-cta__header{
    margin-bottom: 32px;

    @media (max-width: 992px) {
      margin-bottom: 52px;
    }

    h2{
      color: $dark-blue;
      font-family: $font-title;
      font-size: $text-48px;
      font-weight: 600;
      line-height: normal;

      @media (max-width: 992px) {
        text-align: center;
      }
    }
  }

  .report-links-cta__content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }

    .content-ingress{
      width: 45%;
      margin-right: 83px;
      margin-top: 30px;

      @media (max-width: 1440px) {
        margin-top: 0;
      }

      @media (max-width: 992px) {
        width: 100%;
        margin-right: 0;
      }

      p{
        margin-bottom: $text-28px;
        font-size: $text-22px;
        line-height: $text-28px;
        font-weight: 300;
        hyphens: initial;

        &:last-of-type{
          margin-bottom: 0;
        }

        @media (max-width: 992px) {

        }
      }

      a.ingress-link{
        width: 100%;
        margin-top: 40px;
      }
    }

    figure{
      width: calc(55% - 83px);

      @media (max-width: 992px) {
        width: 100%;
        max-width: 428px;
        margin-right: auto;
        margin-bottom: 24px;
        margin-left: auto;
      }

      img{
        width: 100%;
      }
    }
  }
}

