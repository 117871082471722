.search-results{
  margin-bottom: 64px;

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  .aside__wrapper{
    @media (max-width: 1024px) {
      display: none;
    }

    aside{
      padding-top: 114px;

      form.ukom-form{
        fieldset{
          legend{
            margin-bottom: 16px;
            font-size: $text-22px;
            font-weight: 500;
          }

          .input-group{
            margin-bottom: 16px;

            .label-text{
              .amount{
                &::before{
                  content: "(";
                  margin-left: 5px;
                }
                &::after{
                  content: ")";
                }
              }
            }
          }
        }
      }
    }
  }

  .search-results__header{
    display: flex;
    flex-direction: row;
    margin-bottom: 45px;

    @media (max-width: 600px) {
      flex-direction: column;
      flex-flow: wrap;
      margin-bottom: 21px;
    }

    h1{
      margin-right: 107px;
      font-size: $text-48px;
      font-weight: 500;

      @media (max-width: 600px) {
        margin: 0;
        width: 100%;
      }
    }

    .search-bar{
      margin-top: 8px;

      @media (max-width: 600px) {
        width: 100%;
        margin-top: 30px;
      }

      // TAGSINPUT STYLES
      .tagsinput{
        border: none;
        background: $gray-light;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @media (max-width: 600px) {
          width: 100% !important;
        }

        span.tag{
          margin: 4px 8px 2px 0;
          padding: 4px 8px;
          font-size: $text-16px;
          color: $navy;
          background: transparent;
          border: 1px solid $gray-3;

          @media (max-width: 800px) {
            margin: 6px 8px 2px 0;
          }

          a{
            color: $black;
            font-size: $text-16px;
          }
        }

        div{
          input{
            margin: 6px 0 0 0;
            font-size: $text-16px;

            @media (max-width: 800px) {
              margin: 8px 0 0 0;
            }
          }
        }
      }
    }
  }

  .search-filter__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
      flex-direction: row-reverse;
      flex-wrap: wrap-reverse;
    }

    @media (max-width: 600px) {
      flex-direction: column-reverse;
      flex-wrap: inherit;
    }

    .resume{
      @media (max-width: 1024px) {
        width: 100%;
        margin-top: 46px;
      }

      p{
        font-size: $text-16px;
        font-weight: 300;

        .search-string{
          margin-left: 5px;
          font-weight: 500;

          &::before{
            content: "«";
          }

          &::after{
            content: "»";
          }
        }
      }
    }

    .filter-accordion{
      display: none;
      position: relative;

      @media (max-width: 1024px) {
        display: block;
      }

      @media (max-width: 600px) {
        margin-top: 21px;
      }

      button{
        background-color: $gray-light;

        &:focus{
          outline: none;
          box-shadow: none;
        }
      }

      form{
        display: none;
        position: absolute;
        width: 100%;
        padding: 27px 24px 35px 24px;
        background: white;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        z-index: 1;

        .input-group{
          margin-bottom: 16px;

          &:last-child{
            margin-bottom: 0;
          }

          .label-text{
            .amount{
              &::before{
                content: "(";
                margin-left: 5px;
              }
              &::after{
                content: ")";
              }
            }
          }
        }
      }

      &.active{
        width: 280px;

        @media (max-width: 600px) {
          width: 100%;
        }

        button{
          width: 100%;
          justify-content: space-between;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          i, em{
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }
        }

        form{
          border: 1px solid $gray-light;

          @media (max-width: 600px) {
            position: relative;
          }
        }
      }
    }

    form.ukom-form.search-info{
      @media (max-width: 1024px) {
        margin-top: 16px;
      }

      @media (max-width: 600px) {
        margin-top: 0;
      }

      div[role="group"]{
        display: flex;
        flex-direction: row;
        align-items: center;

        h3{
          margin-right: 21px;
          font-size: $text-16px;
          font-weight: 300;
        }

        .input-group{
          margin-right: 16px;
          margin-bottom: 0;

          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }

  .search-results__list{
    margin-bottom: 44px;

    nav.result-list__nav{
      ul.result-list__list{
        li.result-object{
          margin-bottom: 32px;
          background-color: $gray-light;

          &:last-child{
            margin-bottom: 0;
          }

          a{
            display: flex;
            flex-direction: row;

            &:hover{
              text-decoration: none;
            }

            .result-object__content{
              padding: 24px 128px 24px 64px;

              @media (max-width: 600px) {
                padding: 16px;
              }

              header{
                p{
                  margin-bottom: 8px;
                  font-size: $text-16px;
                  text-transform: uppercase;
                  font-weight: 500;
                }

                h2{
                  margin-bottom: 8px;
                  font-family: $font-title;
                  font-size: $text-26px;
                  line-height: 1.36rem;
                  font-weight: 600;
                }
              }

              .ingress{
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
                font-size: $text-22px;
                font-weight: 300;
                line-height: $text-28px;
                overflow-wrap: anywhere;
              }

              .published-date{
                margin-top: 8px;
                font-size: $text-16px;
                font-weight: 300;
              }
            }
          }

          &--report{
            padding: 0;
            background-color: $peach-light;

            a{
              @media (max-width: 600px) {
                flex-direction: column;
              }

              .result-object__img{
                display: flex;
                width: 35%;

                @media (max-width: 600px) {
                  width: 100%;
                }

                figure{
                  @media (max-width: 600px) {
                    padding-top: calc((194 / 327) * 100%);
                  }
                }
              }

              .result-object__content{
                width: 65%;
                padding: 24px 32px 24px 32px;

                @media (max-width: 600px) {
                  width: 100%;
                  padding: 24px 32px;
                }

                .ingress{
                  -webkit-line-clamp: 4; /* number of lines to show */
                }
              }
            }
          }
        }
      }
    }
  }
}
