.ukom-form {
  .input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    label {
      font-size: $text-22px;
      margin-bottom: 8px;
    }

    .hint-label {
      margin-bottom: 17px;
      font-size: $text-16px;
      font-weight: 300;

      a{
        text-decoration: underline;
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="phone"],
    textarea {
      display: block;
      width: 100%;
      padding: 9px 10px;
      border-radius: 4px;
      border: 1px solid $gray-5;
      font-size: $text-22px;
      line-height: 28px;
      font-weight: 300;

      &:disabled {
        background-color: $gray-2;
        border: 2px solid $gray-3;
        color: $gray-4;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $gray-3;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $gray-3;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        color: $gray-3;
      }
    }

    // now used to display error text
    .sub-txt {
      margin-top: 12px;
      padding-left: 10px;
      font-size: $text-16px;
      font-weight: 300;
    }

    .input-bottom-message{
      margin-top: 12px;
      padding-left: 10px;
      font-size: $text-16px;
      font-weight: 300;
    }

    &--error {
      input[type="text"],
      input[type="email"],
      input[type="number"],
      input[type="password"],
      input[type="phone"],
      textarea {
        border: 2px solid $red;
      }

      .sub-txt {
        color: $red-dark;

        &::before{
          content: "!";
          display: inline-flex;
          width: 24px;
          height: 24px;
          border: 2px solid $red-dark;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-right: 8px;
          font-weight: 800;
          line-height: normal;
        }
      }
    }

    // Hide input number arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Hide input number arrows (Firefox)
    input[type=number] {
      -moz-appearance: textfield;
    }

    // hide original inputs
    input[type="checkbox"] , input[type="radio"] {
      // display: none;
      position: absolute;
      width: 1px;
      height: 1px;
      opacity: 0;

      &:focus-visible{
        ~.label-text{
          outline: 1px solid $primary;
          border-radius: 3px;
        }
      }
    }

    // custom input checkbox image
    input[type="checkbox"] + .custom-check {
      position: absolute;
      margin: 2px 0 0 0;
      width: 20px;
      height: 20px;
      background-image: url("./../images/icons/checkbox-idle.svg");
      background-repeat: no-repeat;
      background-position: top left;
      cursor: pointer;
      font-size: 1px;
      color: transparent;
    }

    // checked checkbox
    input[type="checkbox"]:checked + .custom-check{
      background-image: url("./../images/icons/checkbox-selected.svg");
    }

    // custom radio image
    input[type="radio"] + .custom-check {
      position: absolute;
      margin: 0;
      width: 17px;
      height: 17px;
      background-image: url("./../images/icons/radio-idle.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      font-size: 1px;
      color: transparent;
    }

    // radio checked
    input[type="radio"]:checked + .custom-check{
      background-image: url("./../images/icons/radio-selected.svg");
    }

    // label text
    .label-text{
      display: inline-block;
      padding-left: 25px;
      font-size: $text-16px;
      font-weight: 300;
      line-height: 20px;

      a{
        text-decoration: underline;

        &:hover{
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}

.search-bar{
  display: inline-flex;
  flex-direction: row;
  right: 0;
  height: 52px;
  border: 1px solid $gray-3;
  border-radius: 4px;

  input{
    width: 278px;
    height: 100%;
    padding: 0px 16px;
    background-color: $gray-light;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: $text-16px;

    &:focus{
      outline: none;
    }
  }

  button{
    display: flex;
    align-items: center;
    color: $white;
    background-color: $primary;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    font-size: $text-16px;
    font-weight: 500;

    &:hover{
      background-color: $secondary-blue;
      color: #000;

      .icon--search-white {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      color: $black;
      }

      i, em{
        background-image: url('./../images/icons/search-black.svg');
      }

    }

    i, em{
      width: 17px;
      height: 17px;
      margin-left: 10px;
    }
  }
}
