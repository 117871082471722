// ------------------------------------------------------------------      //
// Font: museo-sans                                                        //
// ------------------------------------------------------------------      //
// 100:           @include font('museo-sans', sans-serif, 100, normal);    //
// 300:           @include font('museo-sans', sans-serif, 300, normal);    //
// 500:           @include font('museo-sans', sans-serif, 500, normal);    //
// 700:           @include font('museo-sans', sans-serif, 700, normal);    //

// 100 italic:    @include font('museo-sans', sans-serif, 100, italic);    //
// 300 italic:    @include font('museo-sans', sans-serif, 300, italic);    //
// 500 italic:    @include font('museo-sans', sans-serif, 500, italic);    //
// 700 italic:    @include font('museo-sans', sans-serif, 700, italic);    //

// ------------------------------------------------------------------      //

// ------------------------------------------------------------------------------------------ //
// Font: Source Serif Pro                                                                     //
// ------------------------------------------------------------------------------------------ //
// 400: 				        @include font('Source Serif Pro', serif, 400, normal);                //
// 400 italic: 				  @include font('Source Serif Pro', serif, 400, italic);                //
// 600: 				        @include font('Source Serif Pro', serif, 600, normal);                //
// ------------------------------------------------------------------------------------------ //

// Import Museo Sans
@import url("/_/service/no.seeds.ukom/transform-asset?fontDisplaySwap=https%3A%2F%2Fuse.typekit.net%2Fqvu1ckc.css");

// Import Source Serif
// @import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,400;0,600;1,400&display=swap');

$font: 'museo-sans', sans-serif;
$font-title: 'Source Serif Pro', serif;

// Font size variables
// convert px to rem (base 22px): https://nekocalc.com/px-to-rem-converter
$text-72px: 3.3rem;
$text-66px: 3rem;
// $text-60px: 2.7272rem;
$text-56px: 2.54rem;
$text-48px: 2.2rem;
$text-45px: 2.05rem;
$text-36px: 1.63rem;
$text-32px: 1.45rem;
$text-30px: 1.36rem;
$text-28px: 1.27rem;
$text-26px: 1.15rem;
$text-25px: 1.13rem;
$text-24px: 1.09rem;
$text-22px: 1rem;
$text-20px: 0.91rem;
$text-18px: 0.85rem;
$text-16px: 0.73rem;
$text-15px: 0.68rem;
// Font size variables

html, body {
	font-size: 22px; // html-font-size
  // line-height: 1.27;
  @include font( 'museo-sans', sans-serif, 400, normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: $black;
  // hyphens: auto;
  // word-wrap: break-word;

  @media (max-width: 1440px) {
  font-size: 20px;  // html-font-size
  }

  @media (max-width: 800px) {
    font-size: 18px; // html-font-size
  }
}

p,h1,h2,h3,h4,h5,h6{
  margin: 0;
}

.text-xxl{
  font-size: $text-72px;

  @media (max-width: 800px) {
    font-size: $text-45px;
  }
}

.text-xl{ font-size: $text-48px; }

.text-lg{ font-size: $text-32px; }

.text-md{ font-size: $text-26px; }

.text-sm{ font-size: $text-22px; }

.text-xs{ font-size: $text-18px; }

.text-xxs{ font-size: $text-16px; }

.fw-300{ font-weight: 300; }
.fw-400{ font-weight: 400; }
.fw-500{ font-weight: 500; }
.fw-600{ font-weight: 600; }
