.form-report__wrapper{
  min-height: 730px;
  padding: 72px 0;
  background-color: $gray-light;

  @media (max-width: 600px) {
    padding-top: 24px;
  }

  .form-report{
    .form-report__indicator{
      display: flex;
      flex-direction: row;
      position: relative;
      margin-bottom: 96px;
      z-index: 1;

      @media (max-width: 600px) {
        margin-bottom: 36px;
      }

      &:before{
        content: "";
        position: absolute;
        top: 16px;
        left: 12%;
        width: 76%;
        height: 2px;
        background-color: $primary;
        z-index: -1;
      }

      .step{
        width: 25%;
        text-align: center;
        padding: 0 5px;

        .step-number{
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 9px;
          width: 33px;
          height: 33px;
          font-size: $text-16px;
          font-weight: 300;
          background-color: $white;
          border: 2px solid $primary;
          color: $primary;
          border-radius: 100%;
          cursor: default;
        }

        .step-name{
          font-size: $text-16px;
          font-weight: 300;
        }

        &--complete{
          .step-number{
            background-color: $primary;
            background-image: url('./../images/icons/checkmark-white.svg');
            background-repeat: no-repeat;
            background-position: center;

            span{
              display: none;
            }
          }
        }

        &--active{
          .step-number{
            background-color: $peach;
            border-color: $peach;
            color: $black;
          }

          .step-name{
            font-weight: 500;
          }
        }
      }
    }

    .form-report__step{
      margin-bottom: 96px;

      @media (max-width: 600px) {
        margin-bottom: 56px;
      }

      header.step__header{
        margin-bottom: 32px;

        @media (max-width: 600px) {
          margin-bottom: 16px;
        }

        h3{
          font-family: $font-title;
          // font-size: $text-32px;
          font-size: 1.60rem;
        }
      }

      .role-selector{
        margin-bottom: 56px;

        @media (max-width: 600px) {
          margin-bottom: 32px;
        }

        header{
          margin-bottom: 32px;

          @media (max-width: 600px) {
            margin-bottom: 16px;
          }

          h2, h3{
            font-family: $font-title;
            font-size: $text-32px;
          }
        }

        ul{
          display: flex;
          flex-wrap: wrap;

          li{
            margin-right: 16px;
            margin-bottom: 16px;

            .btn{
              background-color: $white;
              -webkit-box-shadow: none !important;
              box-shadow: none !important;
              cursor: pointer;

              &:hover{
                background-color: $primary;
              }

              &:focus-visible{
                outline: 2px solid black;
              }

              &.active{
                color: #FFFFFF;
                background-color: $primary;
                border-color: $primary;
              }
            }
          }
        }
      }

      .ukom-form {
        h4 , legend, label{
          margin-bottom: 8px;
          font-family: $font-title;
          font-size: 1.30rem;
          font-weight: 600;
          line-height: 1.36rem;
        }

        .input-group{
          margin-bottom: 48px;

          @media (max-width: 600px) {
            margin-bottom: 32px;
          }
        }

        .radio-group , .checkbox-group{
          margin-bottom: 48px;

          @media (max-width: 600px) {
            margin-bottom: 32px;
          }

          h4 , legend{
            margin-bottom: 16px;
          }

          .input-group{
            margin-bottom: 16px;

            &:last-child{
              margin-bottom: 0;
            }
          }

          .input-group--error{
            display: none;
          }

          &--error{
            .options{
              margin-bottom: 24px;
              padding-left: 12px;
              border-left: 2px solid $red-dark;

              .input-group--error{
                display: block;

                .sub-txt{
                  padding-left: 0;
                }

                .input-bottom-message{
                  padding-left: 0;
                }
              }
            }
          }
        }
      }

      // call to action
      .form-report__cta{
        max-width: 520px;
        background-color: $light-blue;
        padding: 32px 32px 50px;

        @media (max-width: 600px) {
          padding: 24px 24px 36px;
        }

        p{
          margin-bottom: 10px;
          font-size: $text-16px;
          font-weight: 300;
          line-height: 0.91rem;
        }
      }

      &#step-1{
        // hide form until role selection
        .ukom-form{
          display: none;

          .input-group{
            textarea{
              @media (max-width: 1024px) {
                max-height: 196px;
              }
            }
          }
        }
      }

      // Hide all steps but 1st
      &#step-2, &#step-3, &#step-4{
        display: none;
      }

      &#step-2{
        .privacy-text{
          margin-bottom: 48px;

          @media (max-width: 600px) {
            margin-bottom: 40px;
          }

          p{
            font-size: $text-22px;
            font-weight: 300;
          }

          form.ukom-form{
            margin-top: 50px;

            fieldset{
              display: flex;
              align-items: center;

              @media (max-width: 600px) {
                margin-bottom: 32px;
              }

              .input-group{
                margin-bottom: 0;

                &:last-child{
                  margin-left: 160px;
                  padding-left: 20px;

                  @media (max-width: 600px) {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }

        .ukom-form--allowContact{
          .input-group{
            position: relative;

            .phone-47{
              margin-top: -41px;
              margin-bottom: 5px;
              margin-left: 16px;
              font-weight: 300;
              opacity: 0.4;
              pointer-events: none;

              @media (max-width: 1440px) {
                margin-top: -39px;
                margin-bottom: 7px;
              }

              @media (max-width: 800px) {
                margin-top: -37px;
                margin-bottom: 8px;
              }
            }

            #phoneNumber{
              max-width: 309px;
              padding-left: 64px;

              @media (max-width: 800px) {
                padding-left: 58px;
              }

              @media (max-width: 600px) {
                max-width: inherit;
              }
            }
          }
        }

        .anonymous-alert{
          display: none;
          max-width: 550px;
          background-color: $light-blue;
          padding: 32px 32px 50px;

          @media (max-width: 600px) {
            padding: 24px 24px 36px;
          }

          p{
            margin-bottom: 10px;
            font-size: 0.8rem;
            font-weight: 300;
            line-height: 0.91rem;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }

      &#step-3{
        .contact-info{
          max-width: 580px;

          .info-item{
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 30px;

            &:last-child{
              margin-bottom: 0;
            }

            @media (max-width: 600px) {
              flex-direction: column;
              margin-bottom: 24px;
            }

            h4{
              width: 47%;
              margin-right: 6%;
              font-family: $font-title;
              text-align: right;
              font-size: $text-22px;
              line-height: 1.27rem;

              @media (max-width: 600px) {
                width: 82%;
                margin-right: 0;
                margin-bottom: 4px;
                text-align: left;
              }
            }

            p{
              width: 31%;
              margin-right: 6%;
              font-size: $text-16px;
              font-weight: 300;
              line-height: 1rem;

              @media (max-width: 600px) {
                width: 84%;
                margin-right: 0;
                padding-right: 16px;
              }
            }

            a{
              width: 10%;
              color: $primary;
              font-size: $text-16px;
              font-weight: 500;
              text-align: right;
              text-decoration: underline;

              @media (max-width: 600px) {
                position: absolute;
                top: calc(50% - 10px);
                right: 5px;
                width: inherit;
              }
            }
          }
        }
      }

      &#step-4{
        .confirmation-text{
          @media (min-width: 1024px) {
            max-width: 87%;
          }

          p{
            margin-bottom: 1.09rem;
            font-weight: 300;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }

        a.btn{
          display: table;
          margin-top: 96px;
          margin-right: auto;
          margin-left: auto;

          @media (max-width: 600px) {
            margin-top: 56px;
          }
        }
      }
    }

    .next-prev-buttons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .next{
        display: none;
        margin-left: auto;
      }

      .prev{
        display: none;
      }
    }

    .error-alert{
      display: none;
      flex-direction: row;
      margin-top: 24px;
      padding: 24px 30px 24px 24px;
      background-color: #FFDBDD;
      border-radius: 8px;

      &.active{
        display: flex;
      }

      @media (max-width: 600px) {
        padding: 16px 24px 16px 16px;
      }

      span{
        display: inline-flex;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border: 2px solid $red-dark;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 10px;
        color: $red-dark;
        font-size: $text-18px;
        font-weight: 800;
        line-height: normal;
      }

      p{
        font-size: $text-16px;
        font-weight: 300;
        line-height: 126.3%;
        color: $red-dark;
      }
    }
  }
}
