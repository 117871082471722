.employee-list{
  margin-bottom: 32px;

  .aside__wrapper{
    @media (max-width: 1024px) {
      margin-bottom: 32px;
    }

    @media (max-width: 600px) {
      margin-bottom: 24px;
    }

    aside{
      padding-top: 114px;

      @media (max-width: 1024px) {
        padding-top: 0px;
      }

      &.active{
        button.filter-btn{
          width: 100%;
          justify-content: space-between;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          i, em{
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }
        }

        form{
          width: 100%;
          padding: 27px 24px 35px 24px;
          background: white;
          border: 1px solid $gray-light;
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
          z-index: 1;
        }
      }

      button.filter-btn{
        display: none;
        background-color: $gray-light;

        &:focus{
          outline: none;
          box-shadow: none;
        }

        @media (max-width: 1024px) {
          display: flex;
        }
      }

      form.ukom-form{
        @media (max-width: 1024px) {
          display: none;
        }

        fieldset{
          legend{
            margin-bottom: 16px;
            font-size: $text-22px;
            font-weight: 500;

            @media (max-width: 1024px) {
              display: none;
            }
          }

          .input-group{
            margin-bottom: 16px;

            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .employee-list__header{
    display: flex;
    flex-direction: row;
    margin-bottom: 45px;

    @media (max-width: 600px) {
      flex-direction: column;
      flex-flow: wrap;
    }

    h1{
      margin-right: 107px;
      font-size: $text-48px;
      font-weight: 500;

      @media (max-width: 600px) {
        margin: 0;
        width: 100%;
      }
    }

    .search-bar{
      margin-top: 4px;

      @media (max-width: 600px) {
        width: 100%;
        margin-top: 30px;
      }

      // TAGSINPUT STYLES
      .tagsinput{
        border: none;
        background: $gray-light;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;

        @media (max-width: 600px) {
          width: 100% !important;
        }

        span.tag{
          margin: 4px 8px 2px 0;
          padding: 4px 8px;
          font-size: $text-16px;
          color: $navy;
          background: transparent;
          border: 1px solid $gray-3;

          @media (max-width: 800px) {
            margin: 6px 8px 2px 0;
          }

          a{
            color: $black;
            font-size: $text-16px;
          }
        }

        div{
          input{
            margin: 6px 0 0 0;
            font-size: $text-16px;

            @media (max-width: 800px) {
              margin: 8px 0 0 0;
            }
          }
        }
      }
    }
  }

  .employee-list__list{
    margin-bottom: 64px;

    .employee-list__object{
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $primary;

      &:last-child{
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      @media (max-width: 800px) {
        margin-bottom: 76px;
        padding-bottom: 0;
        border: none;
      }

      @media (max-width: 800px) {
        margin-bottom: 72px;
      }

      .content{
        display: flex;
        flex-direction: row;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        .employees__img{
          width: 100%;
          max-width: 276px;
          min-width: 276px;
          margin-right: 32px;

          @media (max-width: 600px) {
            margin-right: 0;
            margin-bottom: 16px;
          }
          @media (max-width: 1440px) {
            max-width: 200px;
            min-width: 200px;
          }
        }

        .txt{
          .position{
            margin-bottom: 8px;
            text-transform: uppercase;
            font-size: $text-16px;
          }

          .name{
            margin-bottom: 8px;
            font-family: $font-title;
            font-size: $text-26px;
            font-weight: 600;
          }

          .description{
            margin-bottom: 24px;
            font-size: $text-22px;
            font-weight: 300;
            line-height: 1.27rem;

            @media (max-width: 600px) {
              font-size: $text-18px;
            }

            &.show-read-more{
              .more-text{
                display: none;
              }
            }

            button.read-more , button.read-less{
              margin-left: 3px;
              background: none;
              border: none;
              outline: none;
              color: $black;
              font-size: $text-22px;
              font-weight: 300;
              line-height: 1.27rem;
              text-decoration: underline;

              &:hover{
                text-decoration: none;
                color: $primary;
              }

              @media (max-width: 600px) {
                font-size: $text-18px;
              }
            }
          }

          .phone__wrapper , .mail__wrapper{
            display: inline-block;

            p{
              font-size: $text-16px;
              font-weight: 300;

              &:first-child{
                font-weight: 500;
              }
            }
          }

          .phone__wrapper{
            margin-right: 32px;
            margin-bottom: 10px;

            @media (max-width: 600px) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
