.article-list{
  margin-top: 44px;
  padding: 44px 0;
  background-color: $gray-light;
  &:first-child {
    @media (max-width: 800px) {
      margin-top: -64px;
    }
    @media (max-width: 600px) {
      margin-top: -32px;
    }
  }
  @media (max-width: 800px) {
    padding: 56px 0;
  }

  @media (max-width: 600px) {
    padding: 36px 0;
  }

  .article-list__header{
    margin-bottom: 24px;

    h1{
      font-family: $font-title;
      font-size: $text-66px;
      font-weight: 400;
      line-height: 113.2%;
      letter-spacing: -0.2px;

      @media (max-width: 600px) {
        font-size: $text-45px;
      }
    }
  }

  .article-list__list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: 32px;

    @media (max-width: 600px) {
      margin-right: 0;
      margin-bottom: 56px;
    }

    article{
      display: flex;
      flex-direction: column;
      width: calc(33.3% - 20px);
      margin-right: 20px;
      margin-bottom: 24px;

      @media (max-width: 800px) {
        width: calc(50% - 20px);
        margin-bottom: 20px;
      }

      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0;

        &:last-child{
          margin-bottom: 0;
        }
      }

      a{
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: inherit;
        height: auto;
        padding: 24px;
        background-color: white;
        text-decoration: none;

        &:hover{
          background-color: $light-blue;
        }

        &:focus-visible{
          background-color: $light-blue;
          outline-color: $navy;
        }

        figure{
          margin-bottom: 16px;

          + header{
            margin-top: 0;
          }
        }


        header{
          margin-top: auto;
          margin-bottom: 8px;

          h3{
            font-size: $text-22px;
            font-weight: 400;
            line-height: $text-28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }

        div.lead{
          margin-bottom: 20px;
          font-size: $text-18px;
          font-weight: 300;
          line-height: 126.3%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        footer{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-top: auto;

          p.date{
            font-size: $text-16px;
            font-weight: 300;
            line-height: 126.3%;
          }

          i.icon{
            width: 24px;
            height: 24px;
          }

          em.icon{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
