.image-two-columns{
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  padding: 0 9.5% 0 11.5%;

  @media (max-width: 1440px) {
    padding: 0 3.5%;
  }

  @media (max-width: 1024px) {
    padding: 0 11.5%;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: inherit;
    margin-top: 32px;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  .column{
    width: 100%;

    &:last-child{
      figure{
        margin-bottom: 0;
      }
    }

    figure{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      @media (max-width: 600px) {
        margin-bottom: 32px;
      }

      .image__wrapper{
        width: 43.5%;
        margin-right: 40px;

        @media (max-width: 1024px) {
          margin-right: 24px;
        }

        @media (max-width: 600px) {
          width: 100%;
          margin-right: 0;
        }

        img{
          width: 100%;
        }
      }

      figcaption{
        width: calc(56.5% - 40px);
        margin-top: 0;
        font-family: "museo-sans", sans-serif;
        font-size: $text-22px;

        @media (max-width: 1024px) {
          width: calc(56.5% - 24px);
        }

        @media (max-width: 600px) {
          width: 100%;
          margin-top: 12px;
          font-family: $font-title;
          font-size: $text-18px;
          font-weight: 300;
        }
      }
    }
  }

  &--horizontal{
    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;

      .column{
        width: calc(50% - 20px);

        figure{
          display: block;
          margin-bottom: 0;

          .image__wrapper{
            position: relative;
            width: 100%;
            overflow: hidden;
            padding-top: calc((308 / 426) * 100%);

            img{
              position: absolute;
              top: 50%;
              left: 50%;
              height: 100%;
              object-fit: cover;
              width: 100%;
              transform: translate(-50%, -50%);
            }
          }

          figcaption{
            width: 100%;
            margin-top: 12px;
            font-family: $font-title;
            font-size: 0.85rem;
            font-weight: 300;
          }
        }
      }
    }
  }

  &+* {
    margin-top: 24px;
  }
}
