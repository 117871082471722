.subscription-manager{
  min-height: 50vh;
  margin-top: 64px;
  margin-bottom: 112px;

  @media (max-width: 600px) {
    margin-top: 32px;
    margin-bottom: 64px;
  }

  header{
    h1{
      font-family: $font-title;
      font-size: $text-45px;
    }

    .alert{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      border-left: 4px solid;

      .alert__icon{
        width: 32px;
        height: 32px;
        margin-right: 16px;
        border-radius: 100%;
        text-align: center;

        span{
          color: white;
        }
      }

      .alert__content{
        font-size: 18px;
        font-weight: 300;
      }

      &--success{
        background-color: #d4f1d4;
        border-left-color: #95dcca;

        .alert__icon{
          background-color: #366558;
        }
      }

      &--fail{
        background-color: lighten($color: $red, $amount: 20%);
        border-left-color: $red-dark;

        .alert__icon{
          background-color: $red-dark;
        }
      }

      &--warning{
        background-color: lighten($color: #ffc107, $amount: 35%);
        border-left-color: #ffc107;

        .alert__icon{
          background-color: #ffc107;
        }
      }
    }
  }

  p{
    font-size: $text-18px;
    font-weight: 300;

    a{
      text-decoration: underline;
    }
  }

  .current-subscriptions{
    margin-top: 54px;
    margin-bottom: 54px;

    h2{
      margin-bottom: 32px;
      font-family: $font-title;
      font-size: $text-28px;
    }

    h3{
      margin-bottom: 24px;
      font-size: $text-20px;
      font-weight: 400;
    }

    form.ukom-form{
      .input-group{
        &:last-of-type{
          margin-bottom: 0;
        }
      }

      .btn{
        margin-top: 32px;
      }
    }
  }

  .unsubscribe-link{
    margin-bottom: 16px;
  }

}
