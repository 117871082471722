.report-list__wrapper{
  .report-list{
    .report-list__header{
      h4{
        margin-bottom: 60px;
        color: $dark-blue;
        font-size: $text-48px;
        font-weight: 500;
      }
    }

    .report{
      display: flex;
      flex-direction: row;
      margin-bottom: 96px;

      @media (max-width: 600px) {
        flex-direction: column;
        margin-bottom: 64px;
      }

      .report__img{
        width: 48%;

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      .content{
        width: 52%;
        padding-left: 48px;

        @media (max-width: 800px) {
          padding-left: 24px;
        }

        @media (max-width: 600px) {
          width: 100%;
          padding-left: 0;
          padding-top: 16px;
        }

        header{
          h3{
            margin-bottom: 3px;
            font-family: $font-title;
            font-size: $text-32px;
            line-height: 124%;
          }

          p{
            margin-bottom: 3px;
            font-size: $text-15px;
            font-weight: 300;

            .time{
              font-weight: 500;
            }
          }
        }

        .report-ingress{
          margin-bottom: 16px;
          font-size: $text-22px;
          font-weight: 300;
          line-height: 1.27rem;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;

          @media (max-width: 1440px) {
            -webkit-line-clamp: 2;
          }

          @media (max-width: 600px) {
            -webkit-line-clamp: 3;
          }
        }
      }

      &--img-right{
        flex-direction: row-reverse;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        .content{
          padding-left: 0;
          padding-right: 48px;

          @media (max-width: 800px) {
            padding-right: 24px;
          }

          @media (max-width: 600px) {
            padding-right: 0;
          }
        }
      }
    }

    .show-more{
      margin-bottom: 32px;

      button{
        display: flex;
        margin-right: auto;
        margin-bottom: 32px;
        margin-left: auto;

        &:hover{
          i, em{
            background-image: url('./../images/icons/arrow-light-down.svg');
          }
        }
      }

      .show-more__links{
        display: none;
        max-width: 584px;
        margin-right: auto;
        margin-bottom: 32px;
        margin-left: auto;

        ul{
          li{
            display: flex;
            flex-direction: row;
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $gray-light;

            &:last-child{
              margin-bottom: 0px;
              padding-bottom: 0px;
              border-bottom: none;
            }

            a{
              position: relative;
              width: 80%;
              padding-right: 24px;
              font-family: $font-title;
              font-size: $text-26px;
              line-height: 1.36rem;
              font-weight: 600;

              &:hover{
                text-decoration: none;

                &:before{
                  display: block;
                }
              }

              &:before{
                content: "";
                position: absolute;
                left: -35px;
                top: 2px;
                width: 30px;
                height: 30px;
                display: none;
                transition: 0.2s;
                background-repeat: no-repeat;
                background-image: url(./../images/icons/arrow-blue-right.svg);

                @media (max-width: 600px) {
                  display: none !important;
                }
              }
            }

            time{
              font-size: $text-16px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
