.article__wrapper{
  .article__header{
    color: $dark-blue;
    h1{
      font-family: $font-title;
      font-size: $text-66px;
      font-weight: 400;
      line-height: 113.2%;
      letter-spacing: -0.2px;
      margin-bottom: 32px;
      word-break: break-word;

      @media (max-width: 800px) {
        padding: 0 16px 0 32px;
        font-size: $text-56px;
        word-break: inherit;
      }

      @media (max-width: 600px) {
        padding: 0;
        font-size: $text-48px;
      }
    }

    .featured-image{
      margin-bottom: 32px;

      @media (max-width: 600px) {
        width: 100vw;
        margin-right: -6px;
        margin-left: -6px;
        padding: 0 !important;
      }

      figcaption{
        margin-top: 18px;
        font-family: $font-title;
        font-size: $text-18px;
        padding-left: 11.5%;
        // removed line-height

        @media (max-width: 1440px) {
          padding-left: 3.5%;
        }

        @media (max-width: 1024px) {
          padding-left: 11.5%;
        }

        @media (max-width: 600px) {
          padding: 0 16px;
        }
      }
    }

    .article-meta{
      // display: flex;
      // flex-direction: row;
      margin-bottom: 56px;

      p{
        font-size: $text-16px;
        font-weight: 300;
        margin-bottom: 8px;

        span:first-child {
          margin-right: 32px;
          font-weight: 500;
        }
      }
    }
  }

  .ingress,
  .htmlArea-article-content  p.ingress {
    color: $navy;
    font-weight: 400;
    margin-bottom: 64px;
    padding: 0 11.5%;

    @media (max-width: 1440px) {
      padding: 0 3.5%;
    }

    @media (max-width: 1024px) {
      padding: 0 11.5%;
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    p, &{
      font-size: $text-24px;
      font-style: italic;
    }
  }

  .link-list{
    margin-bottom: 64px;
    padding: 0 11.5%;

    @media (max-width: 1440px) {
      padding: 0 3.5%;
    }

    @media (max-width: 1024px) {
      padding: 0 11.5%;
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    header{
      margin-bottom: 16px;
      font-weight: 500;
    }

    ul{
      position: relative;

      li{
        position: relative;
        margin-bottom: 22px;
        border-radius: 4px;
        background-color: $gray-2;

        &:hover {
            background-color: #D0D0D0;
        }

        &:last-child{
            margin-bottom: 0;
        }

        a{
            display: flex;
            align-items: center;
          padding: 12px 20px;
          color: $gray-5;
          font-size: $text-24px;
          line-height: $text-28px;

          &:hover{
            text-decoration: none;
          }

          i.icon {
            margin-right: 24px;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .table-of-contents{
    margin-bottom: 64px;
    padding: 0 11.5%;

    @media (max-width: 1440px) {
      padding: 0 3.5%;
    }

    @media (max-width: 1024px) {
      padding: 0 11.5%;
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    header{
      margin-bottom: 16px;
      font-weight: 500;
    }

    ul{
      position: relative;

      &::before{
        content: "";
        width: 4px;
        background-color: $secondary-blue-dark;
        height: 92%;
        display: block;
        position: absolute;
        left: 20px;
      }

      li{
        position: relative;
        margin-bottom: 30px;

        &:last-child{
          margin-bottom: 0;
        }

        a{
          display: inline-flex;
          padding-left: 60px;
          text-decoration: underline;

          &:hover{
            text-decoration: none;
            color: $primary;
          }

          &::before{
            content: "";
            position: absolute;
            top: -6px;
            left: 0;
            width: 44px;
            height: 44px;
            display: inline-block;
            background: $turquoise;
            border-radius: 100%;
            background-image: url('../images/icons/arrow-light-right.svg');
            background-repeat: no-repeat;
            background-size: 55%;
            background-position: center;
          }
        }
      }
    }
  }

  .htmlArea-article-content{
    color: $navy;
    figure{
      margin-bottom: 1.5rem;

      &.editor-align-right{
        margin-left: 1rem;
      }

      &.editor-align-left{
        margin-right: 1rem;
      }
    }

    > h2{
      padding: 0 11.5%;
      font-size: $text-32px;
      font-family: $font-title;

      @media (max-width: 1440px) {
        padding: 0 3.5%;
      }

      @media (max-width: 1024px) {
        padding: 0 11.5%;
      }

      @media (max-width: 600px) {
        padding: 0;
      }
    }

    > h1, h2, h3, h4, h5, h6{
      padding: 0 11.5%;
      font-family: $font-title;
      @media (max-width: 1440px) {
        padding: 0 3.5%;
      }

      @media (max-width: 1024px) {
        padding: 0 11.5%;
      }

      @media (max-width: 600px) {
        padding: 0;
      }
    }

    > p{
      padding: 0 11.5%;
      font-size: $text-22px;
      font-weight: 300;
      margin-bottom: 32px;

      @media (max-width: 1440px) {
        padding: 0 3.5%;
      }

      @media (max-width: 1024px) {
        padding: 0 11.5%;
      }

      @media (max-width: 600px) {
        padding: 0;
      }

      &:last-child{
        margin-bottom: 0;
      }

      a{
        text-decoration: underline;
        overflow-wrap: break-word;

        &:hover{
          text-decoration: none;
          color: $primary;
        }
      }
    }
    .fact-box {
      color: $black;
    }

    .imageWrapper{
      margin-top: 24px;
      margin-bottom: 64px;
      padding: 0 11.5%;

      @media (max-width: 1440px) {
        padding: 0 3.5%;
      }

      @media (max-width: 1024px) {
        padding: 0 11.5%;
      }

      @media (max-width: 800px) {
        padding: 0 32px;
      }

      @media (max-width: 600px) {
        padding: 0;
      }
    }

    blockquote{
      margin: 64px 0;
      color: $dark-blue;
      font-family: $font-title;
      padding: 0 calc(11.5%);

      @media (max-width: 1440px) {
        padding: 0 calc(3.5%);
      }

      @media (max-width: 1024px) {
        padding: 0 calc(11.5%);
      }

      @media (max-width: 600px) {
        padding: 0;
      }

      p {
        font-size: $text-32px;
        line-height: 124.3%;
        font-style: italic;

        &.quoted{
          font-size: $text-22px;
          text-align: right;
          text-transform: uppercase;
          color: $black;
          @include font('museo-sans', sans-serif, 300, normal);
          margin-top: 12px;
          letter-spacing: 1px;

          &:before{
            display: none;
          }

          &:after{
            display: none;
          }
        }
      }

      // p:first-child{
      //   &:before{
      //     content: "«"
      //   }
      // }

      // p:nth-last-child(-n+2){
      //   &:after{
      //     content: "»"
      //   }
      // }
    }

    figcaption{
      margin-top: 12px;
      font-family: $font-title;
      font-size: $text-18px;
      font-weight: 300;
    }

    // Macro image-extra-wide
    .image-extra-wide{
      margin-top: 24px;

      @media (max-width: 600px) {
        margin-right: -16px;
        margin-left: -16px;
      }

      figcaption{
        padding-left: 11.5%;

        // text alignment
        @media (max-width: 1440px) {
          padding-left: 3.5%;
        }

        @media (max-width: 1024px) {
          padding-left: 11.5%;
        }

        @media (max-width: 600px) {
          padding: 0 16px;
        }
      }
    }

    // Image placed directly in the htmlArea
    figure{
      &[data-widget="image"],
      &.editor-align-justify,
      &.editor-align-center{
        margin-bottom: 32px !important;
        padding: 0 11.5%;

        @media (max-width: 1440px) {
          padding: 0 3.5%;
        }

        @media (max-width: 1024px) {
          padding: 0 11.5%;
        }

        @media (max-width: 600px) {
          padding: 0;
        }
      }
    }

    >ul, >ol, >dl,
    >ul li ul, >ol li ul, >dl li ul,
    >ul li ol, >ol li ol, >dl li ol,
    >ul li dl, >ol li dl, >dl li dl {
      margin: 0;
      margin-bottom: 32px;
      margin-left: 20px;
      padding: 0 11.5%;
      list-style: inherit;
      font-size: $text-22px;
      font-weight: 300;

      @media (max-width: 600px) {
        margin-left: 0;
        padding-left: 20px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      li{
        margin-bottom: 8px;

        &:last-child{
          margin-bottom: 0;
        }
      }

      a{
        text-decoration: underline;
        overflow-wrap: break-word;

        &:hover{
          text-decoration: none;
          color: $primary;
        }
      }
    }

    >ol{
      list-style: decimal;
    }

    // sublists
    >ul li, >ol li, >dl li{
      ul, ol, dl{
        margin-left: 30px;
        padding: 0;
      }

      ul{
        list-style: circle;
      }

      ol{
        list-style: decimal;
      }
    }

    // >table, thead, th, tbody, tr, td{
    //   padding: 10px;
    //   border: 1px solid $gray-3;
    //   font-size: $text-22px;
    //   font-weight: 300;
    // }

    // >table{
    //   width: 78%;
    //   margin: 0 auto;
    //   hyphens: auto;

    //   @media (max-width: 600px) {
    //     width: 100%;
    //   }

    //   thead{
    //     th{
    //       font-weight: 600;
    //     }
    //   }
    // }
  }

  // Continue Reading Link
  .continue-reading{
    margin: 128px auto 32px;

    @media (max-width: 600px) {
      margin-top: 64px;
    }

    p{
      text-align: center;
      font-size: $text-22px;
      font-weight: 300;

      a{
        margin-left: 10px;
        font-family: $font-title;
        font-weight: 600;
        font-size: $text-26px;

        i, em{
          width: 24px;
          min-width: 24px;
          height: 24px;
          margin-left: 12px;
          vertical-align: middle;
        }
      }
    }
  }

  // PDF Download Button
  .pdf-download-button{
    margin: 64px auto 84px;

    @media (max-width: 600px) {
      margin: 32px auto 64px;
    }

    button{
      display: block;
      margin: 0 auto;
    }
  }

  // PDF Download - CHAPTER
  .pdf-download--chapter{
    margin-top: 96px;
    margin-bottom: 64px;

    @media (max-width: 1024px) {
      margin-top: 64px;
    }

    h4 , .buttons{
      padding: 0 11.5%;

      @media (max-width: 1440px) {
        padding: 0 3.5%;
      }

      @media (max-width: 1024px) {
        padding: 0 11.5%;
      }

      @media (max-width: 600px) {
        padding: 0;
      }
    }

    h4{
      font-family: $font-title;
      margin-bottom: 10px;
      font-size: $text-26px;
      font-weight: 600;
    }

    .buttons{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      div{ // avoid bootstrap styles
        margin: 0;
        padding: 0;
      }

      .pdf-download-button{
        width: auto;
        margin: 0 10px 10px 0;
        padding: 0;

        &:last-child{
          margin-right: 0;
          margin-bottom: 0;
        }

        .btn{
          margin: 0;
          padding: 12px 16px;
          font-size: $text-16px;
        }
      }
    }
  }

}
