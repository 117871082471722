.campaign-module{
  // margin-bottom: 64px;
  padding-top: 52px;
  padding-bottom: 52px;

  @media (max-width: 800px) {
    padding-top: 27px;
  }

  @media (max-width: 600px) {
    // margin-bottom: 32px;
  }

  .recent-news__header{
    margin-bottom: 30px;

    @media (max-width: 800px) {
      margin-bottom: 24px;
    }

    h1{
      font-size: $text-32px;
      font-weight: 500;

      @media (max-width: 600px) {
        font-family: $font-title;
        font-weight: 600;
      }
    }
  }

  .campaign{
    background-color: $peach;
    margin-bottom: 52px;

    @media (max-width: 800px) {
      margin-bottom: 40px;
    }

    @media (max-width: 600px) {
      margin-bottom: 32px;
    }

    .campaign-wrapper{
      display: flex;
      flex-direction: row;
      padding: 32px;
      text-decoration: none;

      @media (max-width: 800px) {
        flex-direction: column;
        padding: 24px;
      }

      .campaign__img{
        width: 38%;
        margin-right: 24px;

        @media (max-width: 800px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px;
        }

        figure{
          @media (max-width: 800px) {
            padding-top: calc((251 / 552) * 100%);
          }

          @media (max-width: 600px) {
            padding-top: calc((251 / 279) * 100%);
          }

          img{

          }
        }
      }

      header{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 600px) {
          flex-basis: inherit; // fix ios bug
        }

        p{
          margin-bottom: 8px;
          color: $navy;
          font-size: $text-18px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.06em;
        }

        h2{
          font-family: $font-title;
          font-size: $text-26px;
          line-height: $text-30px;
          font-weight: 600;
        }

        a{
          padding: 12px 20px;
          width: max-content;
          margin-top: 40px;
        }
      }
    }

  }


}
