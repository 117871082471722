.article__wrapper .htmlArea-article-content,
.text-styled {
  .fact-box{
    position: relative;
    max-width: 751px;
    margin: 56px auto;
    padding: 32px;
    background-color: $peach-light;
    border-radius: 8px;

    @media (max-width: 800px) {
      max-width: inherit;
      margin: 56px 32px;
    }

    @media (max-width: 600px) {
      margin: 32px auto;
    }

    header{
      margin-bottom: 10px;

      h3{
        display: flex;
        align-items: center;
        font-family: $font-title;
        font-size: $text-28px;
        font-weight: 400;
        padding: 0;

        @media (max-width: 600px) {
          font-size: $text-24px;
        }

        i, em{
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }

    p{
      padding: 0;
      font-size: $text-22px;
      font-weight: 300;
      margin-bottom: 32px;

      @media (max-width: 600px) {
        font-size: $text-18px;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      padding: 0;
    }

    a{
      text-decoration: underline;
      overflow-wrap: break-word;

      &:hover{
        text-decoration: none;
        color: $primary;
      }
    }


    >ul, >ol, >dl,
    >ul li ul, >ol li ul, >dl li ul,
    >ul li ol, >ol li ol, >dl li ol,
    >ul li dl, >ol li dl, >dl li dl {
      margin: 0;
      margin-bottom: 32px;
      margin-left: 20px;
      padding: 0;
      list-style: inherit;
      font-size: $text-22px;
      font-weight: 300;

      @media (max-width: 600px) {
        margin-left: 0;
        padding-left: 20px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      li{
        margin-bottom: 8px;

        &:last-child, *:last-child{
          margin-bottom: 0;
        }
      }

      a{
        text-decoration: underline;
        overflow-wrap: break-word;

        &:hover{
          text-decoration: none;
          color: $primary;
        }
      }
    }

    >ol{
      list-style: decimal;
    }

    // sublists
    >ul li, >ol li, >dl li{
      ul, ol, dl{
        margin-left: 30px;
        padding: 0;
      }

      ul{
        list-style: circle;
      }

      ol{
        list-style: decimal;
      }
    }
  }

  // ------------------------------------

  .fact-box.expandable {
    position: relative;
    max-height: 150px;
    overflow: hidden;
    background: #FFEBD6;
    padding: 32px;
    margin-bottom: 20px;
    transition:max-height 0.15s ease-out;
    border-radius: 8px;

    .readmore-link{
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width:100%;
      height: 100px;
      text-align: center;
      padding-top:60px;
      background: rgb(255,235,214);
      background: linear-gradient(180deg, rgba(255,235,214,0) 0%, rgba(255,235,214,1) 70%, rgba(255,235,214,1) 100%);
      cursor: pointer;
      border: none;

      &.expand {
        position: relative;
        background-image: none;
        padding-top:10px;
        height:20px;
      }
    }
  }

  .fact-box.expand{
    max-height: 5000px !important;
    transition:max-height 0.35s ease-in-out;
  }

  .readmore-link{
    display: none;
  }

  .readmore-link:after {
    content: "";
    height: 34px;
    width: 34px;
    background: #fff;
    border-radius: 100%;
    display: inline-block;
    background-image: url("./../images/icons/arrow-blue-down.svg") ;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .readmore-link.expand:after {
    content: "";
    position: relative;
    height: 34px;
    width: 34px;
    display: inline-block;
    background-image: url("./../images/icons/arrow-blue-up.svg") ;
    background-repeat: no-repeat;
  }
}


