.btn{
  font-size: $text-22px;
  padding: 12px 20px;
  font-weight: 300;
  transition: none;
  line-height: normal;

  &.btn-sm{
    font-size: $text-16px;
    padding: 11px 20px;
  }

  &--icon-right , &--icon-left{
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    i, em{
      width: 24px;
      height: 24px;
    }
  }

  &--icon-right{
    i, em{
      margin-left: 10px;
    }
  }

  &--icon-left{
    i, em{
      margin-right: 10px;
    }
  }

  &.btn-primary{
    color: $white;
    background-color: $primary;
    border-color: $primary;

    &:hover{
      background-color: $secondary-blue;
      border-color: $secondary-blue;
    }

    &:focus-visible{
      outline: 3px solid $red;
      box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: $secondary-blue;
      border-color: $secondary-blue;
      -webkit-box-shadow: 4px 4px 0px 0px transparentize($color: $primary, $amount: 0.5);
      box-shadow: 4px 4px 0px 0px transparentize($color: $primary, $amount: 0.5);
    }
  }

  &.btn-secondary{
    color: $black;
    background-color: $white;
    border-color: $primary;
    border-width: 2px;

    &:hover{
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $white;
      background-color: $primary;
      border-color: $primary;
      -webkit-box-shadow: 4px 4px 0px 0px transparentize($color: $primary, $amount: 0.5);
      box-shadow: 4px 4px 0px 0px transparentize($color: $primary, $amount: 0.5);
    }
  }

  &:disabled , &.disabled{
    background-color: $gray-3 !important;
    border-color: $gray-3 !important;
    color: $black !important;
    opacity: 1 !important;
    cursor: default !important;

    &:hover{
      color: $black !important;
      background-color: $gray-3 !important;
      border-color: $gray-3 !important;
    }
  }
}
