.article__wrapper {
    &--examination {
        .article__header {
            h1 {
                margin-bottom: 96px;

                @media (max-width: 600px) {
                    margin-bottom: 32px;
                }
            }

            .status-display {
                display: flex;
                flex-direction: row;
                margin-bottom: 85px;

                @media (max-width: 800px) {
                    padding: 0 16px 0 32px;
                }

                @media (max-width: 600px) {
                    margin-bottom: 32px;
                    padding: 0;
                }

                .status-links {
                    ul {
                        display: flex;
                        flex-direction: row;

                        li {
                            margin-right: 15px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &.active {
                                a {
                                    background-color: $primary;
                                    pointer-events: inherit;
                                }
                            }

                            a {
                                display: block;
                                width: 21px;
                                height: 21px;
                                border: 2px solid $primary;
                                border-radius: 100%;
                                pointer-events: none;
                            }
                        }
                    }

                    .link {
                        margin-top: 8px;

                        a {
                            color: $primary;
                            font-size: $text-20px;
                            text-decoration: underline;

                            &:hover {
                                text-decoration: none;
                                color: $black;
                            }
                        }
                    }
                }

                .txt-wrapper {
                    margin-left: auto;
                    padding-left: 15px;

                    p {
                        margin-bottom: 12px;
                        font-size: $text-16px;
                        font-weight: 300;
                        text-align: right;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .ingress {
            margin-bottom: 24px;

            h3 {
                margin-bottom: 32px;
                font-size: $text-32px;
                font-weight: 500;
            }

            p {
                font-size: $text-25px;
                font-style: normal;
                line-height: 140%;
            }
        }
    }
}
