// Grid config
@import "base/grid-config";

//import bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/custom-forms";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/print";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/root";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/utilities";

//base application
@import "base/mixins";
@import "base/color";
@import "base/typography";
@import "base/global";
@import "base/buttons";
@import "base/icons";
@import "base/image-aspect-ratio";
// @import "base/font-face";

//pages
@import "pages/article";
@import "pages/examination";
@import "pages/chapter";
@import "pages/reports-and-examinations";
@import "pages/user-page";
@import "pages/subscription-manager";
@import "pages/employee-list";
@import "pages/404";
@import "pages/article-list";
@import "pages/front-page";

//components
@import "components/text-styled.scss";
@import "components/loader-download-pdf";
@import "components/forms";
@import "components/main-header";
@import "components/main-footer";
@import "components/breadcrumbs";
@import "components/fact-box";
@import "components/image-two-columns";
@import "components/call-to-action";
@import "components/subscription";
@import "components/employees";
@import "components/accordion-examination";
@import "components/report-list";
@import "components/examinations-list";
@import "components/form-report";
@import "components/search-results";
@import "components/jquery-tagsinput";
@import "components/recent-news";
@import "components/report-links";
@import "components/report-links-cta";
@import "components/campaign-module.scss";
@import "components/responsive-table.scss";
@import "components/filter-header.scss";
@import "components/accordion-user.scss";
@import "components/video.scss";
