// $html-font-size: 16px;
// @function stripUnit($value) {
//     @return $value / ($value * 0 + 1);
// }
// @function rem($pxValue) {
//     @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
// }

@mixin font($family, $serif, $weight, $style) {
	font-family: $family, $serif;
	font-weight: $weight;
	font-style: $style;
}

@mixin background-image($image, $width, $height) {
  background-image: url("../images/"+$image);
  background-repeat: no-repeat;
  background-position: center center;
  width: $width + px;
  height: $height + px;
  content: "";
  display: inline-block;
  background-size: cover;
}
