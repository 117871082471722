.htmlArea-article-content, .text-styled {
  // -----------------------------------------
  // Basic Table styles
  table.bt thead,
  table.bt tbody th {
    display: none;
  }

  table.bt tfoot th,
  table.bt tfoot td,
  table.bt tbody td {
    border: 0;
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    vertical-align: top;
    float: left\9;
    width: 100%\9;
  }

  table.bt tfoot th::before,
  table.bt tfoot td::before,
  table.bt tbody td::before {
    content: attr(data-th) ": ";
    display: inline-block;
    -webkit-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
    font-weight: bold;
    width: calc(6.5em + 16px);
    padding: 16px;
    background-color: $light-blue;
    margin: -16px 16px -16px -16px;
  }

  @media (max-width: 800px) {
    table.bt tfoot th.empty-td:before,
    table.bt tfoot td.empty-td:before,
    table.bt tbody td.empty-td:before {
      content: ' ';
    }
  }

  table.bt tfoot th.bt-hide,
  table.bt tfoot td.bt-hide,
  table.bt tbody td.bt-hide {
    display: none;
  }

  table.bt tfoot th .bt-content,
  table.bt tfoot td .bt-content,
  table.bt tbody td .bt-content {
    vertical-align: top;
  }

  .bt-wrapper.active {
    max-height: 310px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  table.bt.bt--no-header tfoot td::before,
  table.bt.bt--no-header tbody td::before {
    display: none;
  }
  // -----------------------------------------

  // -----------------------------------------
  // UKOM Responsive table styles
  > table{
    position: relative;
    max-width: 751px;
    margin: 56px auto;
    border: 2px solid $primary;

    @media (max-width: 800px) {
      width: 100%;
      max-width: calc(705px - 23%);
      margin: 56px auto;
    }

    @media (max-width: 600px) {
      margin: 32px auto;
    }

    caption{
      margin-bottom: 16px;
      padding: 0;
      caption-side: top;
      text-align: center;
      font-family: $font-title;
      font-size: $text-22px;
      line-height: $text-28px;
      color: $black;
      font-weight: 600;
    }

    thead, th, tbody, tr, td{
      padding: 16px;
      border: 1px solid $primary;
      font-size: $text-20px;
      line-height: $text-24px;
      font-weight: 300;

      @media (max-width: 800px) {
        padding: 12px 16px;
        span {
          display: inline-block;
          padding: 12px 16px;
          margin: -12px -16px;
          &:before {
            padding: 12px 16px;

          }
        }
      }

      strong{
        font-weight: 600;
      }
    }

    thead{
      background-color: $light-blue;
      border-bottom: 2px solid $primary;

      tr{
        th{
          font-size: $text-20px;
          line-height: $text-24px;
          font-weight: 700;
          border-color: $light-blue;

          &:first-child{
            padding-left: 32px;

            @media (max-width: 800px) {
              padding-left: 16px;
            }
          }

          &:last-child{
            padding-right: 32px;

            @media (max-width: 800px) {
              padding-right: 16px;
            }
          }
        }
      }
    }

    tbody{
      tr{
        td{
          &:first-child{
            padding-left: 32px;
            @media (max-width: 800px) {
              padding: 16px;
            }
          }

          &:last-child{
            padding-right: 32px;

            @media (max-width: 800px) {
              padding: 16px;
            }
          }
        }
      }
    }

    &.no-header{
      thead{
        display: none;
      }

      tbody{
        tr{
          td{
            &::before{
              display: none;
            }
          }
        }
      }
    }
  }
}
