.breadcrumbs__wrapper{
  margin-bottom: 96px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid $gray-1;
  border-bottom: 1px solid $gray-1;

  @media (max-width: 800px) {
    margin-bottom: 64px;
  }

  @media (max-width: 600px) {
    margin-bottom: 32px;
  }

  ol.breadcrumbs{
    display: flex;
    align-items: row;

    li{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 6px;
      line-height: normal;

      &:last-child{
        margin-right: 0;

        &::after{
          display: none;
        }

        a{
          pointer-events: none;

          span{
            color: $black;
            text-decoration: none;
          }
        }
      }

      &::after{
        content: "";
        display: inline-block;
        min-width: 20px;
        width: 20px;
        height: 20px;
        margin-bottom: -5px;
        margin-left: 8px;
        background-image: url("./../images/icons/arrow-simple-right.svg");
        background-size: contain;
        background-repeat: no-repeat;
      }

      a{
        &:hover{
          text-decoration: none;

          span{
            text-decoration: none;
            color: $black;
          }
        }

        span{
          color: $primary;
          font-size: $text-16px;
          font-weight: 300;
          text-decoration: underline;
        }
      }
    }
  }

  &.search-active{
    border-top-color: transparent;
  }
}
