.text-styled{
  font-weight: 300;
  > *:last-child{
    margin-bottom: 0!important;
  }
  .text-styled__ingress, .ingress {
    font-weight: bolder;
    margin-bottom: 32px;
    &,
    p{
      font-size: $text-24px;
    }
  }
  .ingress{
    padding: 0;
    margin-bottom: 64px;
    p{
      font-size: $text-24px;
      font-style: italic;
    }
  }
  .table-of-contents, .link-list{
    margin-bottom: 64px;
  }
  figure{
    margin-bottom: 1.5rem;

    &.editor-align-right{
      margin-left: 1rem;
    }

    &.editor-align-left{
      margin-right: 1rem;
    }
  }
  h1 {
    margin-bottom: 56px;
  }

  > h2{
    font-size: $text-32px;
    font-family: $font-title;
  }

  h1, h3, h4, h5, h6{
    font-family: $font-title;
    line-height: 1.5;
  }
  u {
    text-decoration-thickness: 1px;
  }

  p{
    font-size: $text-22px;
    margin-bottom: 32px;
    &:last-child{
      margin-bottom: 0;
    }

    a{
      text-decoration: underline;

      &:hover{
        text-decoration: none;
        color: $primary;
      }
    }
  }

  .imageWrapper{
    margin-top: 24px;
    margin-bottom: 64px;
  }

  blockquote{
    margin: 64px 0;
    color: $primary;
    font-family: $font-title;
    p {
      font-size: $text-32px;
      line-height: 124.3%;
      font-style: italic;
      &:before{
        content: "«"
      }

      &:after{
        content: "»"
      }

      &.quoted{
        font-size: $text-22px;
        text-align: right;
        text-transform: uppercase;
        color: $black;
        @include font('museo-sans', sans-serif, 300, normal);
        margin-top: 12px;
        letter-spacing: 1px;

        &:before{
          display: none;
        }

        &:after{
          display: none;
        }
      }
    }
  }

  figcaption{
    margin-top: 12px;
    font-family: $font-title;
    font-size: $text-18px;
    font-weight: 300;
  }

  // Macro image-extra-wide
  .image-extra-wide{
    margin-top: 24px;

    @media (max-width: 600px) {
      margin-right: -16px;
      margin-left: -16px;
    }

    figcaption{
      @media (max-width: 600px) {
        padding: 0 16px;
      }
    }
  }

  // Image placed directly in the htmlArea
  figure{
    &[data-widget="image"],
    &.editor-align-justify,
    &.editor-align-center{
      margin-bottom: 32px !important;
    }
  }

  >ul, >ol, >dl{
    margin: 0;
    margin-bottom: 32px;
    margin-left: 20px;
    list-style: inherit;
    font-size: $text-22px;

    @media (max-width: 600px) {
      margin-left: 0;
      padding-left: 20px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    li{
      margin-bottom: 8px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    a{
      text-decoration: underline;

      &:hover{
        text-decoration: none;
        color: $primary;
      }
    }
  }

  >ol{
    list-style: decimal;
  }
}
