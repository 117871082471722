.accordion-examination__wrapper{
  padding: 0 11.5%;

  @media (max-width: 1440px) {
    padding: 0 3.5%;
  }

  @media (max-width: 1024px) {
    padding: 0 11.5%;
  }

  @media (max-width: 600px) {
    padding: 0;
  }

  .accordion-examination{
    margin-bottom: 64px;
    background-color: $gray-light;

    @media (max-width: 600px) {
      margin-bottom: 24px;
    }

    &.active{
      .accordion-header{
        i, em{
          transform: rotateZ(180deg);
        }
      }
    }

    .accordion-header{
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 32px 48px;
      background: none;
      border: none;
      cursor: pointer;

      @media (max-width: 600px) {
        padding: 24px;
      }

      &:hover{
        @media (min-width: 600px) {
          background-color: darken($gray-light, 5%)
        }
      }

      ul{
        display: flex;
        flex-direction: row;

        li{
          width: 11px;
          height: 11px;
          margin-right: 8px;
          background-color: $white;
          border: 1px solid $primary;
          border-radius: 100%;

          @media (max-width: 600px) {
            margin-right: 4px;

            &:last-child{
              margin-right: 8px;
            }
          }

          &.fill{
            background-color: $primary;
          }
        }
      }

      h3, .h3{
        font-family: $font-title;
        font-size: $text-28px;
        font-weight: 300;
        padding-right: 16px;
        padding-left: 16px;
        line-height: 120%;
        text-align: left;
      }

      i, em{
        margin-left: auto;
        width: 24px;
        height: 24px;
        min-width: 24px;
        transition: 0.2s;
      }
    }

    .content{
      @extend .text-styled;
      display: none;
      margin-top: 10px;
      padding: 0 48px 32px 48px;

      @media (max-width: 600px) {
        padding: 0 24px 24px 24px;
      }

      h3, h4{
        font-family: "museo-sans", sans-serif;
        font-size: 22px;
        font-weight: 500;
      }

      p{
        margin-bottom: 24px;
        line-height: 1.27rem;
        font-weight: 300;

        &:last-child{
          margin-bottom: 0;
        }

        &.step-description{
          font-weight: 400;
        }

        a{
          text-decoration: underline;

          &:hover{
            text-decoration: none;
            color: $primary;
          }
        }
      }

      ul,ol{
        padding-left: 22px;
        margin: 24px 0;

        li{
          margin-bottom: 8px;
          line-height: 1.27rem;
          font-weight: 300;

          &:last-child{
            margin-bottom: 0;
          }

          a{
            text-decoration: underline;

            &:hover{
              text-decoration: none;
              color: $primary;
            }
          }
        }
      }

      ul{
        list-style: inherit;
      }

      ol{
        list-style: auto;
      }
    }
  }
}
