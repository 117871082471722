.main-footer{
  background-color: $primary;

  .container-fluid{
    max-width: 1280px;
    padding: 43px 0px 0px;

    @media (max-width: 1440px) {
      padding: 43px 80px 0px;
    }

    @media (max-width: 1440px) {
      padding: 43px 64px 0px;
    }

    @media (max-width: 800px) {
      padding: 43px 50px 0px;
    }

    @media (max-width: 600px) {
      max-width: 300px;
      width: 100%;
    }

    .row{
      @media (max-width: 800px) {
        flex-direction: column-reverse;
      }

      .main-footer__a , .main-footer__b{
        display: flex;
        color: $white;

        h3{
          margin-bottom: 12px;
          color: $white;
          font-weight: 500;

          a{
            text-decoration: underline;

            &:hover{
              text-decoration: none;
            }
          }
        }
      }

      .main-footer__a{
        flex-direction: column;

        @media (max-width: 800px) {
          flex-direction: row;
        }

        @media (max-width: 600px) {
          flex-direction: column-reverse;
        }

        .footer-links{
          display: flex;
          flex-direction: column;

          li{
            display: inline-block;
            line-height: 22px;

            a{
              color: $white;
              font-weight: 300;
              text-decoration: underline;
              font-size: $text-16px;

              &:hover{
                text-decoration: none;
              }
            }
          }
        }
      }

      .main-footer__b{
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0 0 100px;

        @media (max-width: 1024px) {
          padding: inherit;
          justify-content: space-around;
        }

        @media (max-width: 800px) {
          justify-content: flex-start;
          margin-bottom: 64px;
        }

        @media (max-width: 600px) {
          flex-direction: column;
          margin-bottom: 38px;
        }

        .content-group{
          padding: 0 10px;

          @media (max-width: 800px) {
            padding: 0;
          }

          &:first-child{
            @media (max-width: 600px) {
              margin-bottom: 38px;
            }
          }

          ul.footer-links{
            display: flex;
            flex-direction: column;

            @media (max-width: 800px) {
              width: 300px;
              margin-right: 38px;
            }

            @media (max-width: 600px) {
              width: 100%;
            }

            li{
              display: inline-block;
              line-height: 22px;

              a{
                display: block;
                color: $white;
                font-weight: 300;
                font-size: $text-16px;
                text-decoration: underline;

                &:hover{
                  text-decoration: none;
                }

                i, em{
                  width: 20px;
                  height: 20px;
                  min-width: 20px;
                  margin-left: 8px;
                  vertical-align: middle;
                }
              }
            }
          }

          .btn-secondary{
            margin-top: 34px;
            border-color: $white;
            background-color: transparent;
            color: $white;

            &:hover{
              background-color: $white;
              color: $primary;
            }

            &:focus-visible{
              outline: 3px solid $red;
              box-shadow: none;
            }
          }

          ul.contact-info{
            li{
              margin-bottom: 16px;
              font-size: $text-16px;

              &:last-child{
                margin-bottom: 0;
              }

              h4{
                font-weight: 500;
              }

              a{
                color: $white;
                text-decoration: underline;

                &:hover{
                  text-decoration: none;
                }
              }

              p{
                font-weight: 300;
              }
            }
          }
        }
      }
    }
  }

  .main-footer__bottom{
    margin-top: 40px;
    padding-top: 32px;
    padding-bottom: 43px;
    border-top: 1px solid $secondary-blue-dark;

    ul{
      padding: 0 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
      }

      li{
        margin-right: 48px;
        color: $white;
        font-size: $text-16px;

        @media (max-width: 600px) {
          margin-right: 0;
          margin-bottom: 40px;

          &:last-child{
            margin-bottom: 0;
          }
        }

        &:last-child{
          margin-right: 0;
        }

        a{
          text-decoration: underline;
          font-weight: 500;

          &:hover{
            text-decoration: none;
          }

          &.social{
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover{
              text-decoration: underline;
            }

            i, em{
              margin-right: 4px;
            }
          }
        }

        p{
          font-weight: 300;
        }
      }
    }
  }
}
