.subscription__wrapper{
  margin: 32px auto;

  .padding-box{
    padding: 0 calc(11.5% - 32px);

    @media (max-width: 1440px) {
      padding: 0 calc(3.5% - 32px);
    }

    @media (max-width: 1024px) {
      padding: 0 calc(11.5% - 32px);
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    .subscription{
      background-color: $peach;
      width: 100%;
      margin: 0 auto;
      padding: 32px 200px 32px 32px;
      border-radius: 4px;

      @media (max-width: 800px) {
        padding: 32px;
      }

      header{
        margin-bottom: 16px;

        h4, h3{
          font-family: $font-title;
          font-size: $text-32px;
          line-height: normal;
        }
      }

      .ukom-form{
        .input-group{
          &--checkbox{
            margin-bottom: 15px;

            @media (max-width: 600px) {
              margin-bottom: 10px;
            }
          }

          &--email{
            flex-direction: row;
            height: 48px;
            margin-top: 9px;
            margin-bottom: 0;

            input[type="email"]{
              max-width: 364px;
              margin-right: 14px;

              @media (max-width: 800px) {
                max-width: 100%;
              }
            }

            .btn{
              padding: 9px 20px;
            }
          }
        }
      }
    }
  }
}
