.examinations-list{
  margin-top: 32px;

  header.examinations-list__header{
    h3{
      margin-bottom: 20px;
      font-size: $text-32px;
      font-family: $font-title;
    }
  }

  .examinations-list__text{
    font-size: $text-22px;
    font-weight: 300;
  }

  .examination-objects{
    margin-top: 52px;

    .examination-objects__header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 18px;

      h4{
        padding-left: 42px;
        font-size: 22px;
        font-weight: 500;

        @media (max-width: 600px) {
          padding-left: 0;
        }
      }

      span{
        display: flex;
        align-items: flex-end;
        padding-left: 16px;
        font-size: 22px;
        font-weight: 300;
        white-space: nowrap;
      }
    }

    ul.examination-objects__list{
      margin-bottom: 52px;

      li.list-item{
        margin-bottom: 22px;
        border-radius: 4px;

        a{
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 12px 20px;
          background-color: $gray-2;
          font-weight: 300;
          line-height: 123%;

          &:hover{
            text-decoration: none;
            background-color: #BDBDBD;
          }

          i, em{
            width: 24px;
            height: 24px;
            min-width: 24px;
            margin-right: 24px;

            @media (max-width: 600px) {
              margin-right: 16px;
            }
          }

          ul{
            display: flex;
            flex-direction: row;
            margin-left: auto;
            padding-left: 16px;

            li{
              width: 11px;
              height: 11px;
              margin-right: 8px;
              background-color: $white;
              border: 1px solid $primary;
              border-radius: 100%;

              @media (max-width: 600px) {
                margin-right: 4px;
              }

              &:last-child{
                margin-right: 0px;
              }

              &.fill{
                background-color: $primary;
              }
            }
          }

            .list-text {
                p:first-of-type {
                    font-size: $text-24px;
                }

                p:last-of-type{
                    margin-top: 8px;
                    font-size: $text-18px;
                    line-height: 1.05rem;
                    color: $primary;
                }
            }
        }
      }
    }

    ul.status-set{
      max-width: 298px;
      margin: 0 auto;
      margin-bottom: 52px;

      li{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $gray-3;

        &:last-child{
          margin-bottom: 0;
        }

        p{
          font-size: $text-15px;
          font-weight: 500;
        }

        ul{
          display: flex;
          flex-direction: row;
          margin-left: auto;
          padding-left: 16px;

          li{
            width: 10px;
            height: 10px;
            margin-right: 6px;
            margin-bottom: 0;
            padding: 0;
            background-color: $white;
            border: 1px solid $primary;
            border-radius: 100%;

            @media (max-width: 600px) {
              margin-right: 4px;
            }

            &:last-child{
              margin-right: 0px;
            }

            &.fill{
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}
