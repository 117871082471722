.article__wrapper{
  &--reports{
    .article__header {
      h1{
        padding: 0;
        font-size: $text-72px;
        line-height: 4.075rem;
        font-weight: 400;

        @media (max-width: 600px) {
            font-size: $text-32px;
            line-height: 1.5;
        }
      }
    }

    .ingress{
      padding: 0;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 32px;

      p {
        font-style: normal;
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
