.user-page{
  .container {
    .ingress {
      margin-bottom: 33px;
      padding: 0;
    }
  }

  .user-page__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: -40px;
    margin-bottom: 40px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 0;
      margin-bottom: 24px;
    }

    h1{
      padding-right: 16px;
      font-family: $font-title;
      font-size: $text-66px;
      font-weight: 400;
      line-height: 113.2%;

      @media (max-width: 800px) {
        font-size: $text-56px;
      }

      @media (max-width: 600px) {
        padding-right: 0;
        margin-bottom: 24px;
        font-size: $text-48px;
      }
    }

    a{
      font-size: $text-18px;
      font-weight: 300;
      text-decoration: underline;

      &:hover{
        text-decoration: none;
        color: $primary;
      }
    }
  }
}
