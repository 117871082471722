// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe, video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  video {
    height: 100%;
    width: auto;
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
	}

  &.aspectRatio--892x495 {
    padding-top: calc((495 / 892) * 100%);
	}

  &.aspectRatio--686x397 {
    padding-top: calc((397 / 686) * 100%);
	}

  &.aspectRatio--892x556 {
    padding-top: calc((556 / 892) * 100%);
	}

  &.aspectRatio--426x308 {
    padding-top: calc((308 / 426) * 100%);
	}

  &.aspectRatio--523x312 {
    padding-top: calc((312 / 523) * 100%);
	}

  &.aspectRatio--276x284 {
    padding-top: calc((284 / 276) * 100%);
	}

  &.aspectRatio--411x302 {
    padding-top: calc((302 / 411) * 100%);
	}

  &.aspectRatio--269x187 {
    padding-top: calc((187 / 269) * 100%);
	}

//   &.aspectRatio--1x1 {
//     padding-top: calc((1 / 1) * 100%);
// 	}

//   &.aspectRatio--4x3 {
//     padding-top: calc((3 / 4) * 100%);
// 	}

//   &.aspectRatio--16x9 {
//     padding-top: calc((9 / 16) * 100%);
//   }

//   &.aspectRatio--395x263 {
//     padding-top: calc((263 / 395) * 100%);
//   }

//   &.aspectRatio--199x220 {
//     padding-top: calc((220 / 199) * 100%);
//   }

}
