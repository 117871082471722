.recent-news{
  background-color: $gray-light;
  // margin-bottom: 64px;
  padding-top: 52px;
  padding-bottom: 52px;

  @media (max-width: 800px) {
    padding-top: 27px;
  }

  @media (max-width: 600px) {
    // margin-bottom: 32px;
  }

  .recent-news__header{
    margin-bottom: 30px;

    @media (max-width: 800px) {
      margin-bottom: 24px;
    }

    h1{
      font-size: $text-32px;
      font-weight: 500;

      @media (max-width: 600px) {
        font-family: $font-title;
        font-weight: 600;
      }
    }
  }

  .campaign{
    background-color: $peach;
    margin-bottom: 52px;

    @media (max-width: 800px) {
      margin-bottom: 40px;
    }

    @media (max-width: 600px) {
      margin-bottom: 32px;
    }

    a{
      display: flex;
      flex-direction: row;
      padding: 32px;
      text-decoration: none;

      @media (max-width: 800px) {
        flex-direction: column;
        padding: 24px;
      }

      .campaign__img{
        width: 38%;
        margin-right: 24px;

        @media (max-width: 800px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px;
        }

        figure{
          @media (max-width: 800px) {
            padding-top: calc((251 / 552) * 100%);
          }

          @media (max-width: 600px) {
            padding-top: calc((251 / 279) * 100%);
          }

          img{

          }
        }
      }

      header{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 600px) {
          flex-basis: inherit; // fix ios bug
        }

        p{
          margin-bottom: 8px;
          color: $navy;
          font-size: $text-18px;
          font-weight: 400;
          text-transform: uppercase;
          letter-spacing: 0.06em;
        }

        h2{
          font-family: $font-title;
          font-size: $text-26px;
          line-height: $text-30px;
          font-weight: 600;
        }
      }
    }

  }

  .recent-news__article-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    @media (max-width: 800px) {
      margin-bottom: 32px;
    }

    article{
      display: flex;
      flex-direction: column;
      width: calc(33.3% - 20px);
      background-color: white;

      &:last-child{
        margin-bottom: 0;
      }

      @media (max-width: 800px) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      a{
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-basis: inherit;
        height: auto;
        padding: 0 24px 28px 24px;
        text-decoration: none;

        &:hover{
          background-color: $light-blue;
        }

        .text-content{
          margin: auto 0;
          padding: 24px 0 20px;

          header{
            margin-bottom: 8px;

            h3{
              font-size: $text-22px;
              font-weight: 400;
              line-height: $text-28px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* number of lines to show */
              -webkit-box-orient: vertical;
            }
          }

          p.lead{
            font-size: $text-18px;
            font-weight: 300;
            line-height: 126.3%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 6; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }

        footer{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p.date{
            font-size: $text-16px;
            font-weight: 300;
            line-height: 126.3%;
          }

          i.icon{
            width: 24px;
            height: 24px;
          }

          em.icon{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  a.more-news{
    display: table;
    margin-left: auto;
    font-size: $text-16px;
    text-decoration: underline;
    font-weight: 400;
    text-align: right;

    &:hover{
      color: $primary;
      text-decoration: none;
    }

    @media (max-width: 800px) and (min-width: 600px) {
      width: calc(50% - 10px);
      position: absolute;
      right: 0;
      text-align: center;
      bottom: 140px;
    }
  }
}
