// Brand
$primary: #006AB4;

$light-blue: #CAEAFA;
$secondary-blue: #00ADE9;
$secondary-blue-dark: #509CC5;

  $peach: #FFAA97;
$peach-light: #FFEBD6;
$peach-dark: #FFD5C1;

// Other
  $red: #DD5C4D;
$red-dark: #C30000;
$navy: #172D50;
  $dark-blue: #162D4F;
$brown: #A37815;
$turquoise: #81CBD4;

// Neutral
$black: #1D1D1D;
$gray-1: #F2F2F2;
$gray-2: #E0E0E0;
$gray-3: #A1A9B0;
$gray-4: #828282;
$gray-5: #4F4F4F;
  $gray-light: $gray-1;
$gray-lighter: #F9F9F9;
$white: #FFFFFF;
