.article__wrapper {
  &--chapter {
    .article__header {
      figure {
        // height: 709px;
        height: 700px;

        @media (max-width: 1440px) {
          height: 540px;
        }

        @media (max-width: 800px) {
          height: 400px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .container {
        margin-top: -185px;

        @media (max-width: 1024px) {
          margin-top: -110px;
        }

        .row {
          .header-content {
            padding: 65px 11.5% 24px;
            background-color: $white;

            @media (max-width: 1440px) {
              padding: 65px 3.5% 24px;
            }

            @media (max-width: 1024px) {
              padding: 32px 32px 24px;
            }

            h3 {
              margin-bottom: 32px;
              font-size: $text-18px;
              text-transform: uppercase;
              color: $primary;
              font-weight: 500;
            }

            h1 {
              display: flex;

              @media (max-width: 800px) {
                margin-bottom: 12px;
              }

              span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                min-width: 48px;
                height: 48px;
                margin-top: 6px;
                margin-right: 16px;
                border-radius: 100%;
                background-color: $peach-dark;
                font-size: $text-22px;
                font-weight: 300;
                font-family: "museo-sans", sans-serif;
                font-style: normal;

                @media (max-width: 800px) {
                  width: 38px;
                  min-width: 38px;
                  height: 38px;
                }

                @media (max-width: 600px) {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }

      &--no-image{
        figure{
          height: 0;
        }

        .container{
          margin-top: 0;
        }
      }
    }

    .ingress {
      @media (max-width: 1024px) {
        padding: 0 32px;
      }

      p {
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  // Chapter Links
  .chapter-links__wrapper {
    background-color: $gray-light;
    padding: 72px 0;

    @media (max-width: 600px) {
      padding: 44px 0;
    }

    .chapter-links {
      max-width: 352px;
      margin: 0 auto;
      padding: 44px;
      background-color: $white;
      border: 1px solid #006AB4;

      @media (max-width: 600px) {
        padding: 32px;
      }

      header {
        h3 {
          margin-bottom: 16px;
          color: $dark-blue;
          font-family: "Source Serif Pro", serif;
          font-size: $text-32px;
        }
      }

      ul {
        li {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          margin-bottom: 16px;

          &:last-child{
            margin-bottom: 0;
          }

          &.active {
            a {
              font-weight: 500;
            }
          }

          a {
            font-size: $text-22px;
            font-weight: 300;
          }

          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            width: 30px;
            min-width: 30px;
            height: 30px;
            border-radius: 100%;
            font-size: $text-16px;
            font-weight: 300;
          }
        }
      }
    }

    &.chapter-links__sticky{
      position: fixed;
      top: calc(50% - 236px);
      right: 0;
      padding: 0;
      background-color: transparent;
      z-index: 9;

      @media (max-width: 1920px) {
        top: calc(50% - 150px);
      }

      @media (max-width: 600px) {
        top: inherit;
        bottom: 16px;
        right: 16px;
      }

      .open-btn{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px 10px;
        background-color: $peach-light;
        border: none;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        @media (max-width: 600px) {
          padding: 0;
          width: 56px;
          height: 56px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i, em{
          width: 24px;
          height: 24px;
          margin-bottom: 8px;

          &.icon--arrow-blue-left{
            @media (max-width: 600px) {
              display: none;
            }
          }

          &.icon--list-menu{
            display: none;

            @media (max-width: 600px) {
              display: block;
              margin-bottom: 0;
            }
          }

          &.icon--menu-close{
            display: none;

            @media (max-width: 600px) {
              display: block;
              margin: 0;
              width: 18px;
              height: 18px;
            }
          }
        }

        span{
          writing-mode: vertical-lr;
          font-size: $text-22px;
          font-weight: 300;

          @media (max-width: 600px) {
            display: none;
          }
        }
      }

      .chapter-links{
        position: absolute;
        top: 0;
        right: -352px;
        width: 400px;
        max-height: 70vh;
        overflow: auto;
        padding: 32px 32px 32px 44px;
        transition: 0.2s;
        border: 1px solid $primary;
        border-right-color: $white;

        &.active{
          right: 0;

          @media (max-width: 600px) {
            display: block;
            right: 24px;
            top: inherit;
            bottom: -16px;
            width: calc(100vw - 64px);
            max-width: inherit;
            max-height: 80vh;
            padding: 32px 32px 64px;
            border-right-color: $primary;
            overflow: auto;
            z-index: -1;
          }
        }

        @media (max-width: 600px) {
          display: none;
        }

        header{
          h3{
            font-size: $text-28px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            i, em{
              cursor: pointer;

              @media (max-width: 600px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
