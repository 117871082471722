// Resets
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.page{
  // overflow: hidden;
  display: flex;
  flex-direction: column;
}

ol, ul, dl {
  margin: 0;
  list-style: none;
}

a{
  color: inherit;

  &:hover{
    color: inherit;
  }
}

figure{
  margin: 0;
}

// Text colors
.text-primary{ color: $primary; }
.text-light-blue{ color: $light-blue; }
.text-secondary-blue{ color: $secondary-blue; }
.text-secondary-blue-dark{ color: $secondary-blue-dark; }
.text-peach{ color: $peach; }
.text-peach-light{ color: $peach-light; }
.text-red{ color: $red; }
.text-red-dark{ color: $red-dark; }
.text-navy{ color: $navy; }
.text-brown{ color: $brown; }
.text-black{ color: $black; }
.text-gray-1{ color: $gray-1; }
.text-gray-2{ color: $gray-2; }
.text-gray-3{color: $gray-3; }
.text-gray-4{ color: $gray-4; }
.text-gray-5{ color: $gray-5; }
.text-white{ color: $white; }

.hide{
  display: none !important;
}

.pagination{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 44px;


  p{
    margin-right: 24px;
    font-size: $text-16px;
    font-weight: 300;

    @media (max-width: 400px) {
      margin-right: 12px;
    }
  }

  button.btn-secondary{ // back button
    margin-right: 24px;
    white-space: nowrap;

    @media (max-width: 400px) {
      margin-right: 16px;
    }
  }

  button.next{
    display: block;
    position: relative;
    padding-right: 54px;

    i, em{
      position: absolute;
      top: calc(50% - 12px);
    }
  }
}
