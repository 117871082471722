.front-page{
  margin-top: 67px;

  @media (max-width: 1024px) {
    margin-top: 32px;
  }

  .front-page__header{
    margin-bottom: 54px;
    color: $dark-blue;

    h1{
      margin-bottom: 32px;
      font-family: $font-title;
      font-size: $text-66px;
      font-weight: 400;
      line-height: 113.2%;
      letter-spacing: -0.2px;

      @media (max-width: 800px) {
        font-size: $text-56px;
        word-break: break-word;
      }

      @media (max-width: 600px) {
        font-size: $text-48px;
      }
    }

    p{
      margin-bottom: $text-28px;
      font-size: $text-22px;
      line-height: $text-28px;
      font-weight: 300;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
