.report-links{
  margin-bottom: 115px;
  padding: 64px 8.5%;
  background-color: $light-blue;

  @media (max-width: 800px) {
    margin-bottom: 64px;
    padding: 32px 24px;
  }

  header.report-links__header{
    margin-bottom: 36px;

    @media (max-width: 600px) {
      margin-bottom: 24px;
    }

    h2{
      color: $dark-blue;
      font-family: $font-title;
      font-size: $text-48px;
      font-weight: 600;
      @media (max-width: 600px) {
        font-size: $text-36px;
      }
    }
  }

  .report-links__content{
    display: flex;
    flex-direction: row;

    @media (max-width: 992px) {
      flex-direction: column;
    }

    figure{
      width: 45%;
      margin-right: 60px;

      @media (max-width: 992px) {
        width: 80%;
        margin: 0 auto 26px auto;
      }

      img{
        width: 100%;
        height: auto;
      }
    }

    .report-links__links{
      width: calc(55% - 60px);

      @media (max-width: 992px) {
        width: 100%;
      }

      > ul{
        margin-bottom: 15px;

        > li{
          margin-bottom: 6px;
          background-color: $gray-light;
          border-radius: 5px;

          &:last-child{
            margin-bottom: 0;
          }

          button{
            width: 100%;
            position: relative;
            display: block;
            padding: 12px 50px 12px 12px;
            font-size: $text-22px;
            font-weight: 300;
            line-height: $text-28px;
            text-decoration: none;
            text-align: left;
            outline: none;
            border: none;
            background-color: transparent;

            &:focus-visible{
              outline: 3px solid;
            }

            &:hover{
              background-color: #BDBDBD;
            }

            i, em{
              position: absolute;
              right: 16px;
              width: 22px;
              height: 22px;
              top: calc(50% - 11px);
              transition: 0.1s;
            }
          }

          .dropdown-content{
            display: none;
            margin: 0 4px;
            padding: 12px;
            background-color: white;
            font-size: $text-22px;
            line-height: $text-28px;
            font-weight: 300;

            a{
              text-decoration: underline;

              &:hover{
                color: $primary;
                text-decoration: none;
              }
            }

            ul, ol{
              padding-left: 20px;
              margin: 10px 0;

              li{
                margin-bottom: 10px;
              }
            }

            ul{
              list-style: revert;
            }

            ol{
              list-style: revert;
            }
          }

          &.active{
            padding-bottom: 4px;

            button{
              i, em{
                -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
              }
            }
          }
        }
      }

      .email-link{
        width: 100%;
      }

      .read-more-link{
        display: none;
        margin-left: auto;
        margin-top: 26px;
        font-size: $text-20px;
        font-weight: 300;
        line-height: $text-28px;
        text-decoration: underline;
        text-align: right;
      }
    }
  }
}
