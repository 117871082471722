.call-to-action__wrapper{
  width: 100%;
  margin: 32px auto;

  .padding-box{
    padding: 0 calc(11.5% - 32px);

    @media (max-width: 1440px) {
      padding: 0 calc(3.5% - 32px);
    }

    @media (max-width: 1024px) {
      padding: 0 calc(11.5% - 32px);
    }

    @media (max-width: 600px) {
      padding: 0;
    }

    .call-to-action{
      display: flex;
      flex-direction: column;
      background-color: $gray-light;
      padding: 32px;

      .title{
        font-size: $text-22px;
        font-weight: 300;
        margin-bottom: 4px;
      }

      .text{
        font-family: $font-title;
        font-size: $text-32px;
        font-weight: 600;
        line-height: 124%;
      }

      a.btn{
        display: block;
        margin: 30px auto 0 0;
      }
    }
  }
}
