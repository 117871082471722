.video {

	&--no-image {
		.video__play {
			display: none;
		}

		.video__poster {
			display: none;
		}
	}

	&__card {
		position: relative;
		background-color: gray;
		align-self: center;
		width: 100%;
	}

	&__figure {
		position: relative;
		width: 100%;

		iframe {
			aspect-ratio: 16/9;
			width: 100%;
			overflow: hidden;
			display: none;
			border: none;
		}

		picture {
			aspect-ratio: 16/9;
			overflow: hidden;
			width: 100%;
			display: block;
		}

		img {
			height: 100%;

		}
	}

	&__poster {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__play-click {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		aspect-ratio: 16 / 9;
		width: 100%;
		top: 0;
		left: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;
		z-index: 0;
	}

	&__play {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
		background-color: #FFD5C1;
		max-width: 150px;
		max-height: 60px;
		border-radius: 50px;
		padding: 20px 32px 20px 24px;
		font-size: 16px;
		white-space: nowrap;
    border: none;

    &:hover {
      background-color: #E5BFAD;
    }
	}

	.icon {
		width: 28px;
		height: 28px;
		filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.25));
	}

	iframe {
		width: 100%;
	}

	&--active,
	&--no-image {

		.video__poster,
		.video__play-click {
			display: none;
			opacity: 0;
			transition: opacity 0.3s ease 0.3s;
		}

		iframe {
			display: block;
			width: 100%;
		}
	}

}
