.page-404{
  margin-bottom: 72px;

  header.page-404__header{
    h1{
      font-family: $font-title;
      font-size: $text-66px;
      font-weight: 400;
      line-height: 113.2%;
      letter-spacing: -0.2px;
      margin-bottom: 32px;

      @media (max-width: 800px) {
        font-size: $text-56px;
      }

      @media (max-width: 600px) {
        font-size: $text-48px;
      }
    }

    h2{
      margin-bottom: 70px;
      font-family: $font-title;
      font-size: $text-26px;
      line-height: $text-30px;
      font-weight: 600;

      @media (max-width: 600px) {
        margin-bottom: 32px;
      }
    }
  }

  .search-bar{
    margin-bottom: 36px;

    @media (max-width: 600px) {
      width: 100%;
    }

    input{
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }

  a.home-link{
    display: table;
  }
}
