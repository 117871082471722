.icon{
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;

  &--plus{
    background-image: url('./../images/icons/plus.svg');
  }

  &--search-blue{
    background-image: url('./../images/icons/search-blue.svg');
  }

  &--search-white{
    background-image: url('./../images/icons/search-white.svg');
  }

  &--menu-close{
    background-image: url('./../images/icons/menu-close.svg');
  }

  &--menu-close-light{
    background-image: url('./../images/icons/menu-close-light.svg');
  }

  &--menu-blue{
    background-image: url('./../images/icons/menu-blue.svg');
  }

  &--list-menu{
    background-image: url('./../images/icons/list-menu.svg');
  }

  &--close-blue{
    background-image: url('./../images/icons/close-blue.svg');
  }

  &--arrow-light-right{
    background-image: url('./../images/icons/arrow-light-right.svg');
  }

  &--arrow-blue-up{
    background-image: url('./../images/icons/arrow-blue-up.svg');
  }

  &--arrow-blue-right{
    background-image: url('./../images/icons/arrow-blue-right.svg');
  }

  &--arrow-blue-down{
    background-image: url('./../images/icons/arrow-blue-down.svg');
  }

  &--arrow-blue-left{
    background-image: url('./../images/icons/arrow-blue-left.svg');
  }

  &--arrow-light-up{
    background-image: url('./../images/icons/arrow-light-up.svg');
  }

  &--arrow-light-right{
    background-image: url('./../images/icons/arrow-light-right.svg');
  }

  &--arrow-light-down{
    background-image: url('./../images/icons/arrow-light-down.svg');
  }

  &--arrow-light-left{
    background-image: url('./../images/icons/arrow-light-left.svg');
  }

  &--facebook-white{
    background-image: url('./../images/icons/facebook-white.svg');
  }

  &--linkedin-white{
    background-image: url('./../images/icons/linkedin-white.svg');
  }

  &--info{
    background-image: url('./../images/icons/info.svg');
  }
}
