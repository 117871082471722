.employees__wrapper{
  max-width: 751px;
  margin: 32px auto;

  .employees{
    padding: 32px;
    background-color: $gray-light;

    header{
      margin-bottom: 32px;

      h4{
        font-family: $font-title;
        font-size: $text-32px;
        line-height: 1.36rem;
      }
    }

    .content{
      display: flex;
      flex-direction: row;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .employees__img{
        width: 100%;
        max-width: 276px;
        min-width: 276px;
        margin-right: 32px;

        @media (max-width: 600px) {
          max-width: inherit;
          min-width: inherit;
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      .txt{
        .position{
          margin-bottom: 8px;
          text-transform: uppercase;
          font-size: $text-16px;
        }

        .name{
          margin-bottom: 8px;
          font-family: $font-title;
          font-size: $text-22px;
          font-weight: 600;
        }

        .description{
          margin-bottom: 24px;
          font-size: $text-22px;
          font-weight: 300;
          line-height: 1.27rem;

          &.show-read-more{
            .more-text{
              display: none;
            }
          }

          &.show-read-more-expand{
            .more-text{
              display: none;
            }
          }

          a.read-more{
            margin-left: 3px;
            text-decoration: underline;
          }

          a.read-less{
            margin-left: 3px;
            text-decoration: underline;
            display: none;
          }
        }

        .phone__wrapper , .mail__wrapper{
          display: inline-block;

          p{
            font-size: $text-16px;
            font-weight: 300;

            &:first-child{
              font-weight: 500;
            }
          }
        }

        .phone__wrapper{
          margin-right: 32px;
          margin-bottom: 10px;

          @media (max-width: 600px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
