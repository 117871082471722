#downloadPdfLoader {
  display: none;
}

.b-loading {
  background-color: rgba(255, 255, 255, 0.4);
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  top: 0;
  &__content {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 15px;
    background-color: white;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 11;
    margin: 0 16px;
  }
  &__spinner {
    position: relative;
    top: 3px;
    margin-right: 10px;
  }
}
