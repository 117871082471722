.main-header{
  width: 100%;
  z-index: 9;
  top: 0;
  background-color: $white;
  margin-bottom: 8px;

  .container-fluid{
    max-width: 1352px;
    padding: 0 56px 0 32px;

    @media (max-width: 1100px) {
      padding: 0 32px;
    }

    @media (max-width: 992px) {
      padding: 0 20px 0 18px;
    }

    .row{
      .header__wrapper{
        display: flex;
        align-items: center;
        height: 120px;

        @media (max-width: 992px) {
          height: 72px;
        }

        .header__logo{
          img{
            width: 220px;

            @media (max-width: 1100px) {
              width: 185px;
            }

            @media (max-width: 992px) {
              width: 140px;
            }
          }
        }

        ul.header__buttons{
          display: flex;
          flex-direction: row;
          margin-left: auto;
          list-style: none;
          position: relative;

          @media (max-width: 992px) {
            display: none;
          }

          li{
            display: flex;
            align-items: center;
            margin-left: 44px;

            @media (max-width: 1280px) {
              margin-left: 22px;
            }

            &.search{
              position: absolute;
              right: 0;
              top: calc(100% + 10px);
            }

            a{
              color: $black;
              font-size: $text-22px;
              font-weight: 500;
              text-decoration: none;
              white-space: nowrap;

              @media (max-width: 1100px) {
                font-size: $text-18px;
              }

              &:hover{
                color: $primary;
              }
            }

            button.search-btn{
              display: flex;
              align-items: center;
              padding: 0;
              outline: none;
              box-shadow: none;
              background-color: $primary;
              padding: 5px 20px;
              border-radius: 100px;

              @media (max-width: 992px) {
                display: none;
              }

              &:focus-visible{
                outline: 1px solid $primary;
              }

              span{
                color: #ffffff;
                font-size: $text-18px;
                font-weight: 500;

                @media (max-width: 1100px) {
                  font-size: $text-18px;
                }
              }

              i, em{
                width: 17px;
                height: 17px;
                margin-left: 10px;
              }
            }
          }
        }

        .search-bar{
          position: absolute;
          top: 133px;
          right: 15px;

          @media (max-width: 1440px) {
            top: 128px;
          }

          @media (max-width: 992px) {
            display: none;
          }

          input{
            background-color: $gray-light;
          }
          button{
            &:hover{
              i{
                filter: brightness(0);
              }
            }
          }
        }

        .open-menu-btn{
          display: none;
          padding: 0;
          margin-left: auto;
          font-size: $text-22px;
          outline: none;
          box-shadow: none;

          @media (max-width: 992px) {
            display: flex;
            align-items: center;
          }

          i, em{
            width: 19px;
            height: 13px;
            margin-left: 7px;
          }
        }

        .menu-mobile{
          position: absolute;
          top: 71px;
          width: 100vw;
          margin-left: -18px;
          padding-bottom: 40px;
          padding: 15px 30px 40px;
          background-color: $white;
          border-bottom: 1px solid $gray-3;
          z-index: 9;

          @media (min-width: 993px) {
            display: none;
          }

          .content{
            max-width: 610px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @media (max-width: 600px) {
              flex-direction: column;
              max-width: 280px;
            }
          }

          .menu-mobile__col{
            width: calc(50% - 10px);

            @media (max-width: 600px) {
              width: 100%;
            }

            ul.menu-mobile__links{
              li{
                margin-bottom: 32px;

                &:last-child{
                  @media (min-width: 600px) {
                    margin-bottom: 0;
                  }
                }

                a{
                  color: $black;
                  font-size: $text-20px;
                  font-weight: 500;
                  text-decoration: none;

                  &:hover{
                    color: $primary;
                  }

                  &.small{
                    font-size: $text-16px;
                  }
                }
              }
            }

            .menu-mobile__search{
              display: flex;
              flex-direction: row;
              max-width: 279px;
              height: 52px;
              border: 1px solid $gray-3;
              border-radius: 4px;

              input{
                max-width: 227px;
                width: 100%;
                height: 100%;
                padding: 0px 16px;
                background-color: $gray-light;
                border: none;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                font-size: $text-16px;

                &:focus{
                  outline: none;
                }
              }

              button{
                display: flex;
                align-items: center;
                padding: 16px;
                color: $white;
                background-color: $primary;
                border-radius: 4px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;

                i, em{
                  width: 17px;
                  height: 17px;
                }
              }
            }

            .lang-links{
              margin-top: 32px;

              a{
                display: table;
                margin-bottom: 24px;
                color: $black;
                font-weight: 300;
                font-size: $text-16px;
                text-decoration: underline;

                &:hover{
                  color: $primary;
                  text-decoration: none;
                }

                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
