.filter-header {
    padding: 0 0 41px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
    }

    &__sorter {
        display: flex;

        .ukom-form {
            display: flex;
            align-items: center;

            .input-group {
                margin-bottom: 0;

                &:first-child {
                    margin-right: 16px;
                }

                input[type="radio"] + label.custom-check {
                    top: 3px;
                    left: 3px;
                }

                span.label-text {
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                }
            }
        }
    }

    &__label {
        font-size: 0.8rem;
        line-height: 1rem;
        margin-right: 13px;
    }

    &__dropdown {
        position: relative;
        z-index: 9;

        @media (max-width: 600px) {
            margin-top: 32px;
        }

        &.open {
            button.btn {
                background-color: $primary;
                color: #fff;

                i.icon {
                    rotate: -180deg;
                }
            }

            .dropdown-body {
                max-height: 500px;
                padding-top: 5px;
                border-bottom: 2px solid $primary;
                border-top: 2px solid $primary;
                // overflow: visible;
            }
        }

        button.btn {
            padding: 8px 8px 8px 15px;
            font-size: 1.1rem;
            line-height: 1.4rem;
            z-index: 1;

            i.icon {
                transition: rotate 200ms ease-in-out;
                filter: brightness(0) saturate(100%) invert(56%) sepia(71%) saturate(346%) hue-rotate(157deg) brightness(88%) contrast(88%);
            }
        }

        .dropdown-body {
            position: absolute;
            background-color: #fff;
            border: $primary 2px solid;
            border-top: none;
            border-bottom: none;
            width: auto;
            min-width: 100%;
            max-width: 327px;
            top: calc(100% - 5px);
            z-index: -1;
            font-weight: 300;
            border-radius: 0.25rem;
            max-height: 0px;
            overflow: hidden;
            transition: max-height 250ms ease-in-out;

            .dropdown-list {
                .dropdown-item {
                    padding: 8px 15px 8px 8px ;
                    &:hover {
                        background-color: $gray-1;
                    }

                    a {
                        display: block;
                        white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}
