.accordion-user {
   margin-bottom: 60px;

   & *:focus {
      outline: black 2px solid;
   }

   &__header {
      h1 {
         padding-right: 16px;
         font-family: $font-title;
         font-size: $text-66px;
         font-weight: 400;
         line-height: 113.2%;
         margin-bottom: 42px;

         @media (max-width: 800px) {
            font-size: $text-56px;
         }

         @media (max-width: 600px) {
            padding-right: 0;
            margin-bottom: 24px;
            font-size: $text-48px;
         }
      }
   }

   &__intro {
      margin-bottom: 33px;

      @media(max-width: 992px) {
         margin-bottom: 24px;
      }

      font-size: $text-20px;
      line-height: $text-24px;
   }

   &__wrapper {
      .accordion-user__item {
         width: 100%;
         position: relative;
         margin-bottom: 22px;

         &:last-of-type {
            margin-bottom: 0;
         }

         &.open {
            button.btn {
               i.icon {
                  rotate: -180deg;
               }
            }

            .accordion-body {
               top: -5px;
               padding-top: 5px;

               .accordion-content {
                  visibility: visible;
               }
            }
         }

         button.btn {
            position: relative;
            z-index: 1;
            display: flex;
            width: 100%;
            justify-content: space-between;
            border: none;
            background-color: $gray-2;
            color: $gray-5;

            &:hover {
               background-color: #D0D0D0;
               color: $gray-5;
            }

            i.icon {
               pointer-events: none;
               transition: rotate 200ms ease-in-out;
               filter: brightness(0) saturate(100%) invert(56%) sepia(71%) saturate(346%) hue-rotate(157deg) brightness(88%) contrast(88%);
            }

            &:focus {
               box-shadow: none;
            }
         }

         .accordion-body {
            position: relative;
            z-index: 0;
            height: 0;
            overflow: hidden;
            transition: height 500ms ease-in-out;

            .accordion-content {
               @extend .text-styled;
               position: relative;
               top: -8px;
               border: 4px solid #E0E0E0;
               border-radius: 4px;
               visibility: hidden;
               padding: 18px 12px 12px 12px;
               font-size: 1rem;
               line-height: 1.27;
               font-weight: 300;

               &>* {
                  // font-size: inherit;
                  // line-height: inherit;
                  // font-weight: inherit;
               }

               >a, p, h1, h2, h3, h4, h5, h6, ul, ol, li  {
                  &:not(:last-child) {
                     margin-bottom: 14px;
                  }
               }

               a {
                  display: block;
                  text-decoration: underline;
                  cursor: pointer;

                  &:hover {
                     color: $primary;
                  }
               }
            }
         }
      }
   }



   .read-more {
      margin-top: 28px;
      display: inline-block;
      font-size: $text-20px;
      line-height: $text-24px;

      * {
         font-size: inherit;
         line-height: inherit;
      }

      a {
         text-decoration: underline;
         text-underline-offset: 3px;
      }
   }
}
